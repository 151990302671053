export const DEFAULT_REGION_ID = 'mid';

// Note the match property can be a string or an array, this is used to match region names in the URL
// This gives us a lot of flexibility in how we can match region names in the URL, they can match id, label or match names (we can even include - url encoded names)
export const ALL_AREAS = {
        label: 'All regions',
        id: 'all',
        match: ['all', 'allregions'],
    };
const NORTH_EAST = {
        label: 'North East',
        id: 'ne',
        match: 'northeast',
    };
const LONDON = {
        label: 'London',
        id: 'l',
        match: 'london',
    };
const SOUTH_EAST = {
        label: 'South East',
        id: 'se',
        match: 'southeast',
    };
const EAST_MIDLANDS_EAST_ANGLIA = {
        label: 'East Midlands & East Anglia',
        id: 'mid',
        match: ['eastmids', 'eastmidlands', 'eastanglia', 'emea', 'eastmidseastang', 'mids'],
    };
const WEST_MIDLANDS = {
        label: 'West Midlands',
        id: 'wm',
        match: ['westmidlands', 'westmids'],
    };
const YORKSHIRE = {
        label: 'Yorkshire',
        id: 'y',
        match: 'yorkshire',
    };
const SOUTH_WEST = {
        label: 'South West',
        id: 'sw',
        match: 'southwest',
    };
const WALES = {
        label: 'Wales',
        id: 'w',
        match: ['wales', 'cymru'],
    };
const SCOTLAND = {
        label: 'Scotland',
        id: 's',
        match: ['scotland', 'scot'],
    };
const IRELAND = {
        label: 'Ireland',
        id: 'i',
        match: ['ireland', 'eire', 'irl'],
    };
const NORTHERN_IRELAND = {
        label: 'Northern Ireland',
        id: 'ni',
        match: ['northernireland', 'northernire', 'ulster'],
    };
const NORTH_WEST = {
        label: 'North West',
        id: 'nw',
        match: 'northwest',
    };

export const AREAS_BY_ID = {
    all: ALL_AREAS,
    ne: NORTH_EAST,
    l: LONDON,
    se: SOUTH_EAST,
    mid: EAST_MIDLANDS_EAST_ANGLIA,
    wm: WEST_MIDLANDS,
    y: YORKSHIRE,
    sw: SOUTH_WEST,
    w: WALES,
    s: SCOTLAND,
    i: IRELAND,
    ni: NORTHERN_IRELAND,
    nw: NORTH_WEST,
};

export const AREAS_BUTTON_ORDER = [
    { label: 'East Midlands\n& Anglia', area: EAST_MIDLANDS_EAST_ANGLIA },
    { label: 'Ireland', area: IRELAND },
    { label: 'London', area: LONDON },
    { label: 'North East', area: NORTH_EAST },
    { label: 'North West', area: NORTH_WEST },
    { label: 'Northern Ireland', area: NORTHERN_IRELAND },
    { label: 'Scotland', area: SCOTLAND },
    { label: 'South East', area: SOUTH_EAST },
    { label: 'South West', area: SOUTH_WEST },
    { label: 'Wales', area: WALES },
    { label: 'West Midlands', area: WEST_MIDLANDS },
    { label: 'Yorkshire', area: YORKSHIRE },
    { label: 'All regions', area: ALL_AREAS },
];

export function findRegionData(search) {
    search = search.toLowerCase();
    // replace - with '' in the search string
    search = search.replace(/-/g, '');
    if (AREAS_BY_ID[search]) {
        return AREAS_BY_ID[search];
    }
    for (const key in AREAS_BY_ID) {
        const region = AREAS_BY_ID[key];
        if (region.match === search) {
            return region;
        }
        if (Array.isArray(region.match)) {
            if (region.match.includes(search)) {
                return region;
            }
        }
        if (region.label === search) {
            return region;
        }
    }
    // Not found
    return null;
}

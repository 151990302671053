export function PreviousWinnersSection({showPreviousWinners}) {
    return (
        <>
            <section className="previous-winners-section" id="prevWinners">
                <div className="section-container">
                    <div className='content'>
                        <div className="previous-winners-content">
                            <h2>Who Stole the Show in 2023?</h2>
                            <p>See Sandwich Sandwich who took home the crown in the 2023 Restaurant of the Year Awards and discover the Pioneers & Innovators restaurants that truly stood out last year.</p>
                            <p>From innovative newcomers to established culinary titans, our winners list is a treasure trove for any foodie.</p>
                            <button className="light" onClick={showPreviousWinners}>Discover our 2023 winners</button>
                        </div>
                    </div>
                </div>
            </section>
            <div className="previous-winners-mob-image-container"></div>
        </>
    )
}
import { useEffect, useState } from 'react';
import ROTYTrophy from '../../uber-assets/small-trophy-logo.svg';
import { AREAS_BUTTON_ORDER, DEFAULT_REGION_ID } from '../model/areas';
import { GetFinalists, GetRestaurantsByRegion, GetWinners } from '../model/restaurants';
import { RestaurantCard } from './restaurant-card/restaurant-card';
import nl2br from 'react-nl2br';
import { PHASES } from '../model/phases';
import { WinnerCard } from './winner-card/winner-card';
import { ROTY_WINNER_ICON } from '../../uber-assets/inline-svgs';

import ROTYBadgeAnim from '../../uber-assets/roty-badge.json';
import Lottie from 'react-lottie';

export function ROTYSection({showRestaurantInfo, instagramFunction, orderFunction, voteFunction, phase, votes, initialRegion, cardTransitionInFunction, cardTransitionOutFunction}) {
    const [activeRegion, setActiveRegion] = useState(initialRegion || DEFAULT_REGION_ID);
    const [nextRegion, setNextRegion] = useState(null);
    const [switchingRegion, setSwitchingRegion] = useState(false);
    const switchRegion = (regionId) => {
        if (!switchingRegion) {
            const switchRegionNow = () => {
                setActiveRegion(regionId);
                setSwitchingRegion(false);
                setTimeout(() => {
                    cardTransitionInFunction('restaurant-card');
                }, 100);
            }

            setSwitchingRegion(true);
            setNextRegion(regionId);
            cardTransitionOutFunction('restaurant-card', switchRegionNow)
        }
    }

    const showFinalists = phase === PHASES.FINALISTS || phase === PHASES.WINNERS;
    const showWinner = phase === PHASES.WINNERS;

    const [regionRestaurants, setRegionRestaurants] = useState([]);
    useEffect(() => {
        // Fetch restaurants for active region
        setRegionRestaurants(showFinalists ? GetFinalists() : GetRestaurantsByRegion(activeRegion));
    }, [activeRegion, phase]);

    useEffect(() => {
        if (initialRegion) {
            setActiveRegion(initialRegion);
        }
    }, [initialRegion]);

    const winner = GetWinners()[0];

    return (
        <section className="roty-section" id="roty">
            <div className="section-container">
                <div className='content'>
                    <div className="column roty-section-heading">
                        {!showFinalists &&
                            <>
                                <div className="section-image-heading-container">
                                    {/* <img className='section-heading-image' src={ROTYTrophy} alt="Restaurant of the Year" /> */}
                                    <div className='section-heading-image'>
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: ROTYBadgeAnim,
                                                rendererSettings: {
                                                    preserveAspectRatio: 'xMidYMid slice'
                                                }
                                            }}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                zIndex: 1,
                                            }}
                                        />
                                    </div>
                                    <h1>Restaurant of the Year</h1>
                                </div>
                                <p>Find below our 130 Finalists from 12 Regions within the UK and Ireland, all eligible to become the 2024 Uber Eats Restaurant of the Year.</p>

                                <div className='cat-buttons-container'>
                                    {AREAS_BUTTON_ORDER.map((area, index) => {
                                        return (
                                            <button className={`cat-button region${((area.area.id === activeRegion && !switchingRegion) || (area.area.id === nextRegion && switchingRegion)) ? ' active' : ''}`} key={index} onClick={(e) => switchRegion(area.area.id)} disabled={area.area.id === activeRegion}>{nl2br(area.label)}</button>
                                        )
                                    })}
                                </div>
                                <select className='cat-drop-down' onChange={(e) => switchRegion(e.target.value)} value={activeRegion}>
                                    {AREAS_BUTTON_ORDER.map((area, index) => {
                                        return (
                                            <option key={index} value={area.area.id}>{area.label}</option>
                                        )
                                    })}
                                </select>
                            </>
                        }
                        {showFinalists && !showWinner &&
                            <>
                                <div className="section-image-heading-container">
                                    {/* <img className='section-heading-image' src={ROTYTrophy} alt="Restaurant of the Year" /> */}
                                    <div className='section-heading-image'>
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: ROTYBadgeAnim,
                                                rendererSettings: {
                                                    preserveAspectRatio: 'xMidYMid slice'
                                                }
                                            }}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                zIndex: 1,
                                            }}
                                        />
                                    </div>
                                    <h1>Restaurant of the Year</h1>
                                </div>
                                <p className='extra-bottom-pad'>Meet our 12 incredible finalists, chosen by popular vote and now in the running to be crowned the 2024 Uber Eats Restaurant of the Year! Check them out below and see who's one step closer to taking the top spot!</p>
                            </>
                        }
                        {showWinner &&
                            <>
                                <div className="section-image-heading-container">
                                    {/* <img className='section-heading-image' src={ROTYTrophy} alt="Restaurant of the Year" /> */}
                                    <div className='section-heading-image'>
                                        <Lottie
                                            options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: ROTYBadgeAnim,
                                                rendererSettings: {
                                                    preserveAspectRatio: 'xMidYMid slice'
                                                }
                                            }}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                zIndex: 1,
                                            }}
                                        />
                                    </div>
                                    <h1>Restaurant of the Year 2024</h1>
                                </div>
                                <div>
                                    <p>{winner.name} has been crowned Uber Eats Restaurant of the Year, beating stiff competition from across the UK and Ireland. {winner.name} has received £100,000 in prize money, to help invest and grow their business.</p>
                                    <p>After being selected as one of 12 regional finalists by the public from a field of over 130 restaurants, {winner.name} impressed the expert judging panel to win. We’re proud to have {winner.name} on the Uber Eats platform, and look forward to seeing all of their future successes.</p>
                                    <p className='em'>Congratulations to all of our amazing finalists, who each receive a £5,000 runner-up prize.</p>
                                </div>
                                {GetWinners().map((winner, index) => {
                                    return (
                                        <WinnerCard key={index} icon={ROTY_WINNER_ICON} category="Restaurant of the Year 2024" winner={winner} showRibbon={true} infoFunction={showRestaurantInfo} instagramFunction={instagramFunction} orderFunction={orderFunction} />
                                    )
                                })}
                                <div>
                                    <div className='extra-bottom-pad'></div>
                                    <h3 className='extra-bottom-pad'>Uber Eats Restaurant of the Year 2024 finalists</h3>
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className="cards-container">
                    {regionRestaurants.map((restaurant, index) => {
                        return (
                            <RestaurantCard key={index} restaurant={restaurant} infoFunction={showRestaurantInfo} instagramFunction={instagramFunction} orderFunction={orderFunction} voteFunction={voteFunction} phase={phase} alreadyVoted={votes.indexOf(restaurant.id) !== -1} finalist={showFinalists} showWinner={showWinner} />
                        )
                    })}
                </div>
            </div>
        </section>
    );
}
import { AREAS_BY_ID } from '../../model/areas';
import './restaurant-card.css';

import LocationIcon from '../../../uber-assets/location-icon.svg';
import { INFO_ICON, INSTA_ICON } from '../../../uber-assets/inline-svgs';
import FinalistRibbon from '../../../uber-assets/finalist-ribbon.svg';
import WinnerRibbon from '../../../uber-assets/winner-ribbon.svg';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CustomDot } from '../carousel/custom-dot';
import { PHASES } from '../../model/phases';
import { useEffect, useState } from 'react';

export function RestaurantCard({ restaurant, infoFunction, instagramFunction, orderFunction, voteFunction, phase, alreadyVoted, finalist, animIndex, showWinner }) {
    const [autoPlay, setAutoPlay] = useState(false);
    const hasMultipleImages = Array.isArray(restaurant.image) && restaurant.image.length > 1;

    useEffect(() => {
        let t = setTimeout(() => {
            setAutoPlay(true);
        }, Math.floor(Math.random() * 3000));
        return () => {
            clearTimeout(t);
        }
    }, []);

    return (
        <div className="restaurant-card card-anim-start">
            {!Array.isArray(restaurant.image) &&
                <div className="restaurant-card__image" style={{backgroundImage: `url(./img/${restaurant.image})`}}></div>
            }
            {Array.isArray(restaurant.image) &&
                <Carousel
                    swipeable={hasMultipleImages}
                    draggable={hasMultipleImages}
                    showDots={hasMultipleImages}
                    customDot={<CustomDot />}
                    arrows={hasMultipleImages}
                    responsive={{all: {breakpoint:{min: 0, max: 1000000}, items: 1}}}
                    ssr={false} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={false /*restaurant.image.length > 1 ? autoPlay : false*/}
                    autoPlaySpeed={3000}
                    keyBoardControl={false}
                    transitionDuration={500}
                    containerClass="restaurant-card__image"
                    dotListClass=''
                >
                    {
                        restaurant.image.map((image, index) => {
                            return (
                                <div key={index} className="restaurant-card__image carousel-inner" style={{backgroundImage: `url(./img/restaurants/${image})`}}></div>
                            )
                        })
                    }
                </Carousel>
            }
            <div className="restaurant-card__info">
                <h2 className="restaurant-card__info__name">{restaurant.name}</h2>
                <div className="restaurant-card__info__location">
                    <img className='small-info-icon' src={LocationIcon} alt='Location icon' />
                    <p>{AREAS_BY_ID[restaurant.region].label}</p>
                </div>
                <div className='grow'></div>
                <div className={`restaurant-card__info__buttons${phase === PHASES.VOTE ? ' top-row' : ''}`}>
                    <button className="restaurant-card__info__buttons__info square light" onClick={() => infoFunction(restaurant)}>{INFO_ICON}</button>
                    {restaurant?.instagram !== '' &&
                        <button className="restaurant-card__info__buttons__instagram square light" onClick={() => instagramFunction(restaurant)}>{INSTA_ICON}</button>
                    }
                    <button className={`restaurant-card__info__buttons__order${phase === PHASES.VOTE ? ' light' : ' black'}`} onClick={() => orderFunction(restaurant)}>Order</button>
                    {phase === PHASES.VOTE &&
                        <>
                            {!alreadyVoted &&
                                <button className="restaurant-card__info__buttons__vote-inline black" onClick={() => voteFunction(restaurant.id, restaurant.region)}>Vote now</button>
                            }
                            {alreadyVoted &&
                                <div className='voted-holder inline'>Thanks for voting</div>
                            }
                        </>
                    }
                    {phase !== PHASES.VOTE &&
                        <button className="restaurant-card__info__buttons__vote-inline black" onClick={() => orderFunction(restaurant)}>Order</button>
                    }
                </div>
                {phase === PHASES.VOTE &&
                    <div className="restaurant-card__info__buttons">
                        {!alreadyVoted &&
                            <button className="restaurant-card__info__buttons__vote black" onClick={() => voteFunction(restaurant.id, restaurant.region)}>Vote now</button>        
                        }
                        {alreadyVoted &&
                            <div className='voted-holder under'>Thanks for voting</div>
                        }
                    </div>
                }
            </div>
            {finalist &&
                <img className='card-ribbon' src={(showWinner && restaurant.isWinner) ? WinnerRibbon : FinalistRibbon} alt='Finalist' />
            }
        </div>
    );
}

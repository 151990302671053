import ComingSoonLogo from '../../uber-assets/logo_coming_soon.png';

export function ComingSoon() {
    return (
        <div className="coming-soon">
            <div className='coming-soon-content'>
                <img src={ComingSoonLogo} alt="Launching Soon" />
                <h1>LAUNCHING SOON</h1>
            </div>
        </div>
    )
}
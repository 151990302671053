export function GetNomineesByCategory(category) {
    return NOMINEES.filter(nominee => nominee.category === category);
}

const WINNERS = [1, 5, 6, 9];

export const GetWinners = () => {
    return NOMINEES.filter(nominee => WINNERS.includes(nominee.id));
}

export const EMPTY_TRAILBLAZER = {
    id: 0,
    name: '',
    category: '',
    restaurant: '',
    location: '',
    instagram: '',
    description: '',
    image: 'restaurantPlaceholder.jpg',
    winImage: '',
    sharelink: '',
    orderlink: '',
};

export const NOMINEES = [
    {
        id: 1,
        name: 'Sara Ibrahim',
        category: 'female_chef',
        restaurant: 'Smoke & Pepper',
        location: 'London',
        instagram: 'https://www.instagram.com/smokeandpepperldn/',
        description: `As a strong woman in business, I believe women’s abilities are often underestimated. I’ve worked tirelessly to move Smoke & Peppers forward, helping grow it into the community hub it is today. From making key decisions to creating a vision that reflects our values, I’ve been deeply involved in every step of the journey. As a co-owner, I’ve shown that women can not only manage a business but lead it to success.

Our restaurant has brought the community together by hosting events, supporting local causes, and building partnerships with organisations that help those in need. We’ve created opportunities for others, especially women, and have made it a priority to give back. But this is just the beginning—there is so much more we plan to achieve. I’m committed to pushing Smoke & Peppers even further, proving that women in business can break barriers and make a lasting impact.`,
        image: ['trailblazers/SmokeandPepper-SaraIbrahim.jpeg', 'trailblazers/SmokePepperBow1.jpg', 'trailblazers/SmokePepperBow2.jpg', 'trailblazers/SmokePepperBow3.jpg', 'trailblazers/SmokePepperBow4.jpg'], // 'placeholder-trailblazers/nom1.jpg', // Supports Carousels: ['placeholder-trailblazers/nom1.jpg', 'placeholder-trailblazers/nom2.jpg', 'placeholder-trailblazers/nom3.jpg'],
        winImage: '2024-winners/sarah-ibrahim-2024.jpg',
        isWinner: true,
        sharelink: 'https://www.ubereats.com/gb/store/smoke-%26-pepper-bow/CKERs5jqWyCOcj5-u_sw-A?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/smoke-%26-pepper-bow/CKERs5jqWyCOcj5-u_sw-A?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
    },
    {
        id: 2,
        name: 'Anna Robertson',
        category: 'female_chef',
        restaurant: 'Freddy & Hicks',
        location: 'Glasgow',
        instagram: 'https://www.instagram.com/freddyandhicks/?hl=en-gb',
        description: `We are a team of 3 female cooks, all mothers with 8 kids between us, working with the best ingredients we can find to nourish our customers! We use grass-fed Scottish beef in our smash burgers and make our veggie & vegan patties to a secret family recipe, fresh every day. We want to offer something comforting for everyone no matter their dietary requirements, all under one roof.

We started doing street food in 2016 and moved to a cafe in 2019. We serve grass-fed beef, halal chicken, veggie & vegan burgers, loaded fries, mac & cheese, loaded salads & luxurious milkshakes. We offer something for everyone!

We are trying to serve the best quality comfort food with lots of love and a feminine touch. In 5 years we hope to still be going strong and have some of children working with us, one big family!`,
        image: ['trailblazers/FreddyHicks-AnnaRobertson.jpeg', 'trailblazers/FreddyHicks_2.jpg'], // 'placeholder-trailblazers/nom1.jpg', // Supports Carousels: ['placeholder-trailblazers/nom1.jpg', 'placeholder-trailblazers/nom2.jpg', 'placeholder-trailblazers/nom3.jpg'],
        winImage: '2023-winners/female-chef-2023.jpg',
        sharelink: 'https://www.ubereats.com/gb/store/freddy-%26-hicks/pK8BzomcWemHJZYIs43zzQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/freddy-%26-hicks/pK8BzomcWemHJZYIs43zzQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
    },
    {
        id: 3,
        name: 'Sam & Natalie Stonehouse',
        category: 'female_chef',
        restaurant: 'The Bagelry',
        location: 'Liverpool',
        instagram: 'https://www.instagram.com/bagelrypool/?hl=en',
        description: `Our business is female owned and we have a predominantly female team, with women in all managerial and leadership roles. We believe in empowering our team by providing training and development opportunities as well as progression within the business. We also believe in work-life balance and so we make sure our team has a few days off in a row, do not work unsociable hours and also have a balance working weekends and weekdays.

Thriving as a small independent business in today’s climate is difficult, especially within an ever expanding City such as Liverpool where competition is growing. We are constantly having to rethink and reshape our business to stand out in the crowd. We thrived throughout lockdown and grew from this experience, changing our business model from a more relaxed, sit in café to a delivery service and fast paced fast food service in store.   

Over the years we have been involved in many community events and supported local groups and charities. We have provided out of hours shop use for a transgender support group; sponsored a local LGBTQI+ rugby team; taken part in Liverpool PRIDE; supported local artists to create our logos and merch, and last year we hosted LFC players as part of their ‘work experience’ series. We will also be providing placement opportunities for a local College in the coming weeks.`,
        image: ['trailblazers/SamandNat.jpg', 'trailblazers/TheBagelry_2.jpg', 'trailblazers/TheBagelry_3.jpg'], // 'placeholder-trailblazers/nom1.jpg', // Supports Carousels: ['placeholder-trailblazers/nom1.jpg', 'placeholder-trailblazers/nom2.jpg', 'placeholder-trailblazers/nom3.jpg'],
        winImage: '2023-winners/female-chef-2023.jpg',
        sharelink: 'https://www.ubereats.com/gb/store/the-bagelry/NIUptebEVAaMGHEj8Fk9mw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/the-bagelry/NIUptebEVAaMGHEj8Fk9mw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
    },
    

    {
        id: 4,
        name: 'Tofu Vegan',
        category: 'sustainable_restaurant',
        restaurant: 'Tofu Vegan',
        location: 'London',
        instagram: 'https://www.instagram.com/tofuveganlondon/?hl=en',
        description: `Taking on the challenge of being one of the first Chinese Vegan restaurants in UK.

Tofu Vegan's impact on the Vegan community has been massive which we are aiming to, and we will keep going to work hard offering the best to our customers. They can now indulge themselves with an extensive menu. 

We offer freshly cooked dishes, home made Tofu all free from unnecessary products.

We go beyond just cooking the food. 

Our team know how best to recommend and combine the different tastes and textures to give our guest an inspiring way to enjoy what Tofu Vegan has to offer.

Our majority ( more than 65 percent ) regular customers who keep coming back are meat eaters, that shows something special and makes Tofu standing out among those. `,
        image: ['trailblazers/TofuVegan.jpg', 'trailblazers/TofuVegan2.jpg', 'trailblazers/TofuVegan3.jpg', 'trailblazers/TofuVegan4.jpg'], // 'placeholder-trailblazers/nom2.jpg',
        winImage: 'trailblazers/winners/TofuVegan.jpg',
        isWinner: false,
        sharelink: 'https://www.ubereats.com/gb/store/tofu-vegan-angel/_IuNZPeySoCFYPDxBepDkw?chain_slug=tofu-vegan&diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522_IuNZPeySoCFYPDxBepDkw%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/tofu-vegan-angel/_IuNZPeySoCFYPDxBepDkw?chain_slug=tofu-vegan&diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522_IuNZPeySoCFYPDxBepDkw%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D&ps=1',
    },
    {
        id: 5,
        name: 'Loki Poke',
        category: 'sustainable_restaurant',
        restaurant: 'Loki Poke',
        location: 'Bristol',
        instagram: 'https://www.instagram.com/lokipokeuk/?hl=en',
        description: `We recently won the Sustainability Award at the British Street Food Awards - evaluated by the The Sustainable Restaurant Association, for ""going above and beyond to ensure that their food not only tastes good, but does good too"". We were chosen for the award for excelling in three areas: Sourcing, Society and the Environment.

As a business owner, I have worked hard to prioritise the following:

1) Working only with suppliers who care as much about the ethics and sustainability of their ingredients as we do.
2) Being responsible with the ingredients we choose to put on our menu.
3) Making the effort to support and really be part of our local community.
4) Looking after our team and creating a work environment that is supportive, fun, offers fair pay, and rewards and encourages progression.

SOURCING
Most of our main ingredients are traceable back to farm or fishery:
Our meat is 100% organic and locally sourced from Stream Farms (a local organic farm which practices sustainable and regenerative farming). We are supplied our meat by Beast Butchers, a local family butcher.
We buy our salmon from Loch Duart, a low-impact Scottish farm that prioritises fish quality while maintaining a low carbon footprint. 
We’ve swapped the sashimi tuna on our menu (typically sourced from the Indian Ocean or the Western Pacific Ocean) for Hampshire Chalk Stream Trout, a locally sourced, sustainable alternative.

ENVIRONMENT
We’ve used carbon footprint software to analyse our events and festival menu, so we could adapt our recipes, ingredients, and where we source certain items from to help us reduce our carbon footprint.

SOCIETY
Our work with local charities including donating food and equipment to Feed The Homeless, and raising money for the Mazi Project (a local charity working to empower marginalised 16- to 25-year-olds through food).`,
        image: ['trailblazers/loki_poke_1.jpg', 'trailblazers/loki_poke_2.jpeg'], // 'placeholder-trailblazers/nom2.jpg',
        isWinner: true,
        winImage: '2024-winners/loki-poki-2024.jpg',
        sharelink: 'https://www.ubereats.com/gb/store/loki-poke/ktD1gfGzUsqeBJ2kz379zA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/loki-poke/ktD1gfGzUsqeBJ2kz379zA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
    },

    {
        id: 12,
        name: 'The Street Food Chef',
        category: 'sustainable_restaurant',
        restaurant: 'The Street Food Chef',
        location: 'Sheffield',
        instagram: 'https://www.instagram.com/streetfoodchefsheffield/',
        description: `We’re a family run Mexican street food outlet passionate about providing a healthy fast food alternative to the people of Sheffield. We began selling our food in 2010 on Sheffield’s streets and have been massively supported by the local community. We've received five awards from Eat Sheffield, and are proud to have a reader recommendation in the Good Food Guide and a runner up award in the Observer Food Awards.

We make sure all our food is from local business ensuring they're healthy and supporting local business. Which also adds to climate impact as everything delivered and sourced locally. We believe that fast food can taste great, without any of the added nasties – all our food is freshly prepared every day and we use locally sourced, fresh ingredients wherever possible. We make a point of working with local businesses.`,
        image:  ['trailblazers/Street_food_chef_1.jpg', 'trailblazers/street_food_chef_2.jpg'], // 'placeholder-trailblazers/nom3.jpg',
        winImage: 'trailblazers/Street_food_chef_1.jpg',
        sharelink: 'https://www.ubereats.com/gb/store/the-street-food-chef-the-canteen/QsFFz9TDXgKhy5s2dddN9A?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/the-street-food-chef-the-canteen/QsFFz9TDXgKhy5s2dddN9A?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
    },
    
    {
        id: 6,
        name: 'Burger Drop',
        category: 'social_impact',
        restaurant: 'Burger Drop',
        location: 'Newcastle',
        instagram: 'https://www.instagram.com/burgerdropuk/',
        description: `What may be small change for our customers, can be life changing for others. Charity is a core part of our business, from our charity buckets in our restaurant to our own donations from business profit; here is what we have achieved so far.

2024 - £80,000 raised for Syrian & Palestinian Refugee Camps in Lebanon.

2023 - 2x Homes Built for Syrian Refugees.

2022 - £200 raised and an awareness campaign ran for the local People’s Kitchen.

2021 - £1,000 raised to celebrate selling our 10,000th burger was donated to a national mental health charity, Mental Health Concern.`,
        image: ['trailblazers/Burger_Drop_1.jpg', 'trailblazers/Burger_Drop_2.jpg',], // 'placeholder-trailblazers/nom2.jpg',
        winImage: '2024-winners/burger-drop-2024.jpg',
        isWinner: true,
        sharelink: 'https://www.ubereats.com/gb/store/burger-drop-westgate-road/uwcKQAubQSy0UbcvHrYovg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/burger-drop-westgate-road/uwcKQAubQSy0UbcvHrYovg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
    },
    {
        id: 7,
        name: 'Alfie\'s',
        category: 'social_impact',
        restaurant: 'Alfie\'s',
        location: 'Newcastle',
        instagram: 'https://www.instagram.com/alfiesfriedchicken/',
        description: `We are a neighbourhood bar and restaurant always looking to give back to community in all aspects of our work. 

Our main social impact is the close work we do with Cash for Kids who’s mission is to improve the lives of disadvantaged children and young people in our communities who are affected by poverty, illness, neglect or have additional needs. 

We push for donations across our Alfies & our other brand Brack Burger and also promote Alfie’s as a collection point for there Christmas campaign, Mission Christmas, where we were flooded with toys and gifts for the charity. We also host a Christmas party each year where every penny from the bar in sales as well as auctions as well as other monetary fundraising ideas. Last year alone we raised £7200 and collected over 200 toys!

This year we have done £5000 in donations so far before the main Christmas campaign has even begun! We would be looking at donating any prize money from this award to our chosen charity to further support them. `,
        image: ['trailblazers/Alfies_1.jpg', 'trailblazers/Alfies_2.jpg', ], // 'placeholder-trailblazers/nom2.jpg',
        winImage: '2023-winners/sustainable-2023.jpg',
        sharelink: 'https://www.ubereats.com/gb/store/alfies-fried-chicken/66-VLJAFWsCpO4U3vj3DBQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/alfies-fried-chicken/66-VLJAFWsCpO4U3vj3DBQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
    },
    {
        id: 8,
        name: 'China Red 满庭红',
        category: 'social_impact',
        restaurant: 'China Red 满庭红',
        location: 'Sheffield',
        instagram: 'https://www.instagram.com/chinaredsheffield/',
        description: `Community Engagement:

We actively participate in local community events and fundraising activities. For example, we've supported charities like Sheffield Children Hospital, Mums In Need, Roundabout, and Archer Project. We foster a sense of unity and cultural appreciation by engaging with diverse community groups and delivering events that bridge cultural gaps and promote inclusivity. Notably, the Sheffield Lunar Chinese New Year Festival, supported by """"China Red,"""" has raised over £18k for Sheffield Children Hospital Charity.

Cultural Awareness:

We highlight Chinese culture through various events, performances, and displays, increasing cultural awareness and understanding among the wider community. This promotes respect and appreciation for cultural diversity.

Economic Impact:
As a key stakeholder in the Sheffield Lunar Chinese New Year Festival, """"China Red"""" helps bring the South East Asian community together to deliver a city event that attracts an additional 35k visitors to Sheffield City Centre. This boost in footfall benefits local businesses and the overall economy.

Collaboration and Partnerships:
We collaborate with organizations like Sheffield City Council, Sheffield Chamber of Commerce, and international partners such as the Foshan Municipal Government and Chengdu City. These partnerships enhance the quality and reach of our projects, bringing unique cultural elements and fostering international connections.

Our efforts contributed to the signing of the Twin City connection between Chengdu (China) and Sheffield (UK).`,
        image: 'trailblazers/ChinaRed_1.jpeg', // 'placeholder-trailblazers/nom2.jpg',
        winImage: '2023-winners/sustainable-2023.jpg',
        sharelink: 'https://www.ubereats.com/gb/store/china-red-%E6%BB%A1%E5%BA%AD%E7%BA%A2/3RcUJcafR4y53_przXyPug?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/china-red-%E6%BB%A1%E5%BA%AD%E7%BA%A2/3RcUJcafR4y53_przXyPug?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
    },

    {
        id: 9,
        name: 'Urban Health',
        category: 'health_focused',
        restaurant: 'Urban Health',
        location: 'Dublin',
        instagram: 'https://www.instagram.com/urbanhealth_ie/?hl=en',
        description: `Since 2014 Urban Health has been promoting healthy eating and providing our customers with healthier alternatives to their most loved dishes. We were one of the first healthy eating cafe's in dublin and have made a hugely positive impact on our beloved area of Ranelagh by offering our community healthy food choices.

We specialise in cold pressed juices, vegan friendly foods including salads, wraps, breakfast and brunch dishes, smoothie bowls, açai and healthy raw treats. Our cold pressed juice cleanses are one of our top selling products which we deliver all over Ireland. We make all our food in house with our amazing team of chefs and we always try to offer a plant based option on all menu items we create. We use fresh, seasonal ingredients from top quality suppliers within Ireland. Our eggs are free-range and our meats are sourced from top Irish suppliers. We like to support other small businesses as much as possible and offer a range of off the shelf products from a number of local Irish businesses. We are 95% general waste free and all of our packaging is either compostable or recyclable. 

We believe in gently encouraging people to choose more plant based alternatives and by doing so, cut down on their intake of animal products in the hope that it might have a positive effect on both the environment and on peoples health and wellbeing. We believe in promoting healthy eating in a holistic way and believe that what you put into your body effects how you feel and your mental health. By providing people with healthier alternatives we hope that it will lead to an overall more positive mindset for our customers and the community around us.`,
        image: ['trailblazers/Urban_health_1.jpeg', 'trailblazers/Urban_health_2.jpeg'], // 'placeholder-trailblazers/nom4.jpg',
        winImage: '2024-winners/urban-health-2024.jpg',
        isWinner: true,
        sharelink: 'https://www.ubereats.com/ie/store/urban-health-ranelagh/tIVWYXUsUcW7dA4C3FngQw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
        orderlink: 'https://www.ubereats.com/ie/store/urban-health-ranelagh/tIVWYXUsUcW7dA4C3FngQw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
    },

    {
        id: 10,
        name: 'Eat Clean',
        category: 'health_focused',
        restaurant: 'Eat Clean',
        location: 'Newcastle',
        instagram: 'https://www.instagram.com/eatclean.uk/',
        description: `Our healthy eating business Eat Clean in Whitley Bay excels by offering a unique blend of convenience, quality, and community focus. We stand out by providing freshly prepared, nutritionally balanced meals that cater to all ages, ensuring that our offerings are both delicious and health-conscious. What sets us apart is our commitment to sourcing the highest quality ingredients, including grade A chicken and grass-fed beef, directly from local suppliers. This not only guarantees freshness but also supports the local economy.

Eat Clean collaborates closely with local gyms, tailoring meal plans to meet specific fitness goals, and actively engage with the community through events and partnerships. Our impact is evident in the healthier lifestyles we promote, the strong relationships we’ve built with local businesses, and the positive feedback from our customers. By prioritizing both taste and nutrition, we’ve become a trusted hub for healthy living in Whitley Bay, making a tangible difference in our community’s well-being.`,
        image:  ['trailblazers/EatClean_1.jpeg', 'trailblazers/EatClean_2.jpeg',], // 'placeholder-trailblazers/nom4.jpg',
        winImage: 'trailblazers/winners/EatClean_1.jpg',
        isWinner: false,
        sharelink: 'https://www.ubereats.com/gb/store/eat-clean/tiE_FSxqTNy4H-GkJdyIIQ?srsltid=AfmBOoqrb9_zj1Herd8SNfqUIOjCgDJuv3simB14nb_3zLPOCAljgwFR',
        orderlink: 'https://www.ubereats.com/gb/store/eat-clean/tiE_FSxqTNy4H-GkJdyIIQ?srsltid=AfmBOoqrb9_zj1Herd8SNfqUIOjCgDJuv3simB14nb_3zLPOCAljgwFR',
    },

    {
        id: 11,
        name: 'Happy Maki',
        category: 'health_focused',
        restaurant: 'Happy Maki',
        location: 'Brighton',
        instagram: 'https://www.instagram.com/thehappymaki/?hl=en',
        description: `I believe Happy Maki is the perfect solution to healthy, filling and delicious food. You can eat Happy Maki 7 days in a week and still feel great, I and many others have tried and tested this and its true. All of our salads and sauces are home made containing whole-foods ingredients, for example, our carrot salad is marinated in orange juice, turmeric and fresh ginger and garlic.

Our home made miso soup contained a 7 blend mushroom powder and is garnished with fresh sprouted mung beans.  We slip in the really good stuff wherever we can! Our menu is also gluten free friendly which helps so many people who have big and small adverse reactions to gluten heavy meals. 

We use a lot of herbs in our sushi, and every-meal of ours is packed with freshly prepped vegetables meaning our sushi burritos and bowls are bursting with colour and nutrients.I have long been passionate about public health and healthy food doesn't need to be boring or not filling.

I believe we have found the sweet spot between satisfying and nourishing.`,
        image: ['trailblazers/HappyMaki_1.jpeg', 'trailblazers/Happy_Maki2.jpg',], // 'placeholder-trailblazers/nom4.jpg',
        winImage: '2023-winners/health-2023.jpg',
        sharelink: 'https://www.ubereats.com/gb/store/happy-maki-burritos-%26-bowls/zdo_GJN1QeOcv4l5MRTbGQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/happy-maki-burritos-%26-bowls/zdo_GJN1QeOcv4l5MRTbGQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
    },
    
]

export function findNomineeData(search) {
    search = search.toLowerCase();
    // replace - with '' in the search string
    search = search.replace(/-/g, '');
    for (const nominee of NOMINEES) {
        const n = nominee.name.toLowerCase().replace(/ /g, '');
        const r = nominee.restaurant.toLowerCase().replace(/ /g, '');
        if (nominee.name.toLowerCase() === search || n === search || r === search || nominee.instagram === search || nominee.id === parseInt(search)) {
            return nominee;
        }
        if (nominee.match) {
            if (nominee.match === search) {
                return nominee;
            }
            if (Array.isArray(nominee.match)) {
                if (nominee.match.includes(search)) {
                    return nominee;
                }
            }
        }
    }
    // Not found
    return null;
}

import FooterLogo from '../../uber-assets/footer-ubereats-small-logo-black.svg';

export function Footer() {
    return (
        <footer>
            <div className='section-container'>
                <div className="content footer-container">
                    <FooterContent />
                </div>
            </div>
        </footer>
    )
}

export function FooterContent({isInMenu}) {
    return (
        <>
            <div className="footer-image-container">
                <img className={`footer-logo${isInMenu ? ' in-menu' : ''}`} src={FooterLogo} alt="footer-logo" />
            </div>
            <div className={`footer-content${isInMenu ? ' in-menu' : ''}`}>
                <p>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</p>
                <p>All submitted data will be treated in accordance with our <a href="https://www.uber.com/legal/en/" target='_blank' rel="noreferrer">Privacy Policy</a> and <a href="./Uber Eats UKI ROTY 2024 - Terms and Conditions.pdf" target='_blank' rel="noreferrer">Terms and Conditions.</a></p>
                <p>© 2024 Uber Technologies Inc.</p>
                <p><a href="https://www.kokodigital.co.uk/" target="_blank" rel="noreferrer">Website developed by Koko Digital</a></p>
            </div>
        </>
    )
}

import './prev-winner-card.css';

import LocationIcon from '../../../uber-assets/location-icon.svg';
import { SHARE_ICON, INSTA_ICON } from '../../../uber-assets/inline-svgs';
import Carousel from "react-multi-carousel";
import { CustomDot } from "../carousel/custom-dot";

export function PrevWinnerCard({ icon, category, winner, shareFunction, instagramFunction, orderFunction }) {
    return (
        <div className="prev-winner-card">
            <div className="prev-winner-card__top-banner">{icon}<p>{category}</p></div>
            {!Array.isArray(winner.image) &&
                <div className="prev-winner-card__image" style={{backgroundImage: `url(./img/${winner.image})`}}></div>
            }
            {Array.isArray(winner.image) &&
                <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={true}
                    customDot={<CustomDot />}
                    arrows={false}
                    responsive={{all: {breakpoint:{min: 0, max: 1000000}, items: 1}}}
                    ssr={false} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    keyBoardControl={false}
                    transitionDuration={500}
                    containerClass=""
                    dotListClass=''
                >
                    {
                        winner.image.map((image, index) => {
                            return (
                                <div key={index} className="prev-winner-card__image" style={{backgroundImage: `url(./img/${image})`}}></div>
                            )
                        })
                    }
                </Carousel>
            }
            <div className="prev-winner-card__info">
                <h2 className="prev-winner-card__info__name">{winner.name}</h2>
                {winner.restaurant && 
                    <h3>{winner.restaurant}</h3>
                }
                <div className="prev-winner-card__info__location">
                    <img className='small-info-icon' src={LocationIcon} alt='Location icon' />
                    <p>{winner.location}</p>
                </div>
                <div className='grow'></div>
                <div className="prev-winner-card__info__buttons">
                    <button className="prev-winner-card__info__buttons__info square light" onClick={() => shareFunction(winner)}>{SHARE_ICON}</button>
                    <button className="prev-winner-card__info__buttons__instagram square light" onClick={() => instagramFunction(winner)}>{INSTA_ICON}</button>
                    <button className="prev-winner-card__info__buttons__order black" onClick={() => orderFunction(winner)}>Order</button>
                </div>
            </div>
        </div>
    );
}
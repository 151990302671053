/**
 * Social Sharing for Facebook and Twitter.
 * @author Carl Trelfa
 * 
 * Usage:
 * 
 * You never need to instantiate the Facebook or Twitter classes. Just access them via shorthand.fb or shorthand.tw or koko.social.fb / tw
 * 
 * 
 * EXPORTED CONSTANTS AND STAND ALONE FUNCTIONS
 * 
 * const SHARE_URL
 * 
 *      This is just a convenient single place to stick your share url so you don't have to have it littered throughout your project.
 * 
 * const SHARE_TITLE
 * 
 *      This is only used by the native share dialog (we'll get to that next)
 * 
 * 
 * function showNativeShareDialog(fallbackCallback, title = '', url = '', queryPart = '')
 * 
 *      Display a native share dialog, this is most useful on mobile, although Chrome on Windows (and other browser / os combinations)
 * do support it. On Chrome for Windows you need to download / install the facebook app for this to be useful - I'd stick with mobile!
 * 
 * param fallbackCallback
 *      A callback in case the native dialog is not available. You probably want to show a view with FB / TW buttons
 * param title
 *      Defaults to SHARE_TITLE, although you can set this using this param
 * param url
 *      The url to share, deaults to SHARE_URL
 * param queryPart
 *      A query string that will be appended to the url, after a ?
 * 
 * 
 * function copyShareUrlToClipboard(url = '', queryPart = '') 
 * 
 *      A handy utility function - does exactly what it says!
 * 
 * param url
 *      The url to share, deaults to SHARE_URL
 * param queryPart
 *      A query string that will be appended to the url, after a ?
 * 
 * 
 * 
 * FACEBOOK
 * 
 * We're using FB.ui, in order to do that your index file needs the following code, just below the <body> tag (an appId is REQUIRED):
 * 
    <script>
      window.fbAsyncInit = function() {
        FB.init({
          appId            : 'your-app-id',
          autoLogAppEvents : true,
          xfbml            : true,
          version          : 'v11.0'
        });
      };
    </script>
    <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js"></script>
 *
 * FB.ui makes it easy to share stuff via a familiar interface - we don't need to handle any logged in checks,
 * the user can edit what they are sharing and will have confirmation the share has been posted.
 * 
 * I named it share_ui because we also have a simple share style that doesn't require the above script.
 * 
 * 
 * Your share url will need Facebook meta data on it (see https://developers.facebook.com/docs/sharing/webmasters#markup):
 * 
    <meta property="og:url"                content="http://www.something.com" />
    <meta property="og:title"              content="Some Title" />
    <meta property="og:description"        content="Some description" />
    <meta property="og:image"              content="http://www.something.com/img/share/shareimg.jpg" />
 *
 * From Facebook documentation:
 * 
 * Title: The title of your article without any branding such as your site name.
 * Description: A brief description of the content, usually between 2 and 4 sentences. This will displayed below the title of the post on Facebook.
 * Image: You should use a 1.91:1 image ratio, such as 600x314px, we have used 1200x628px in the past.
 * 
 * Share debugger url: https://developers.facebook.com/tools/debug
 * 
 * 
 * I recommend using static meta data for this and pointing your share url to the page on the clients web site containing the meta data.
 * They are responsible for adding and maintaining the meta data on their site and pointing to an appropriate image (which we could supply).
 * Our share copy (which could include your score) should be passed in as a message which is included as a quote in the share,
 * the quote can be removed by the person sharing, but can't be edited.
 * 
 * You could use php to generate dynammic meta data for scoring, but this always causes a faff with the clients, so I wouldn't recomend it.
 * There is a php file that does this included at public/share/index.php.
 * 
 * The php code takes some data from the query string on the url to dynamically populate the meta data, 
 * then there is a single line of Javascript that redirects to the actual page that the game runs on.
 * 
 * 
 * TWITTER
 * 
 * We currently only have a simple method of Twitter sharing implemented:
 * 
 * shorthand.tw.share_simple(text, hashTags = '', url = '', queryPart = '')
 * 
 * This works by opening a small window showing a pre-filled Twitter dialog.
 * We could post directly to Twitter but this method means we don't need to build any tweet confirmation pop ups, allows users to edit
 * the tweet before they post it and handles logging in, so I think for most purposes it should be fine.
 * 
 * You need to add Twitter Card meta data to your page (again included in public/share/index.php, but as above I recommend having the client
 * handle including static meta data on their page).
 * 
    <meta name="twitter:card" content="summary_large_image">
    <meta name="twitter:site" content="@twitteruser">
    <meta name="twitter:creator" content="@twitteruser">
    <meta name="twitter:title" content="Site Title">
    <meta name="twitter:image:src" content="https://path/to/your/img.jpg">
    <meta name="twitter:domain" content="https://site.com">
 * 
 * Twitter card validator: https://cards-dev.twitter.com/validator
 * Documentation: https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started
 * 
 * We should probably implement this version at some point: https://developer.twitter.com/en/docs/twitter-api/early-access
 * 
 * For browser games I think this simple method is fine, maybe for app games we need to use the api proper.
 */

    export const SHARE_URL = 'https://your/share/url/';
    export const SHARE_TITLE = 'Native share dialog title';
    
    /**
     * Show a native share dialog. Most useful on mobile, maybe check koko.client.system.isMobile before calling.
     * @param {Function} fallbackCallback - called if share dialog unavailable
     * @param {String} title - a title for the dialog
     * @param {String} url - url to share, defaults to SHARE_URL
     * @param {String} queryPart - if included this is appended to the end of the url after a ?
     */
    export function showNativeShareDialog(fallbackCallback, title = '', url = '', queryPart = '') {
        url = url === '' ? SHARE_URL : url;
        title = title === '' ? SHARE_TITLE : title;
        if (navigator.share) {
            navigator.share({
                title: title,
                url: queryPart === '' ? url : url + '?' + queryPart,
            }).then(() => {
                console.log('Thanks for sharing!');
            })
                .catch(console.error);
        } else {
            // fallback
            console.log('Sharing not available!');
            // you should probably show a panel with fb / tw buttons on for triggering standard sharing
            // v.get('sharepop').transitionIn();
            fallbackCallback();
        }
    }
    
    /**
     * Copy a url to the clipboard. Does what it says on the tin.
     * @param {String} url - url to share, defaults to SHARE_URL
     * @param {String} queryPart - if included this is appended to the end of the url after a ?
     */
    export function copyShareUrlToClipboard(url = '', queryPart = '') {
        url = url === '' ? SHARE_URL : url;
        // Create new element
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = queryPart === '' ? url : url + '?' + queryPart;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px', display: 'none'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
    }
    
    /**
     * Facebook sharing
     */
    class FB {
        /**
         * The recommended way to do facebook shares. They handle all kinds of edge cases for you.
         * @param {String} message - the message to share, this is our share copy, maybe include the player's score
         * @param {String} hashTag - FB only supports a single hashtag
         * @param {String} url - url to share, defaults to SHARE_URL
         * @param {String} queryPart - if included this is appended to the end of the url after a ?
         */
        share_ui(message = '', hashTag = '', url = '', queryPart = '') {
            let fb = window.FB || null;
            // console.log('fb share ui', fb);
            if (fb !== null) {
                url = url === '' ? SHARE_URL : url;
                url = queryPart === '' ? url : url + '?' + queryPart;
                fb.ui({
                    method: 'share',
                    href: url,
                    quote: message,
                    hashtag: hashTag,
                }/*, function(response){}*/);
            } else {
                this.share_simple(message, hashTag, url, queryPart);
            }
        }
    
        /**
         * The simplest way to handle facebook sharing, used as a fallback for share_ui, but there is nothing to 
         * stop you using this directly. An advantage of this method is you don't need an appId.
         * @param {String} message - the message to share, this is our share copy, maybe include the player's score
         * @param {String} hashTag - FB only supports a single hashtag
         * @param {String} url - url to share, defaults to SHARE_URL
         * @param {String} queryPart - if included this is appended to the end of the url after a ?
         */
        share_simple(message = '', hashTag = '', url = '', queryPart = '') {
            // console.log('fb share simple');
            url = url === '' ? SHARE_URL : url;
            url = queryPart === '' ? url : url + '?' + queryPart;
            let quotePart = message === '' ? '' : '&quote=' + encodeURIComponent(message);
            let hashTagPart = hashTag === '' ? '' : '&hashtag=' + encodeURIComponent(hashTag);
            let fbSharer = 'https://www.facebook.com/sharer.php?u=' + encodeURIComponent(url) + quotePart + hashTagPart;
            window.open(fbSharer, '_blank');
        }
    }
    
    /**
     * Twitter sharing
     */
    class TW {
        /**
         * The simplest way to to twitter sharing!
         * @param {String} text - the message to share, this is our share copy, maybe include the player's score
         * @param {String} hashTags - Twitter supports a multiple hashtags, in a comma seperated list
         * @param {String} url - url to share, defaults to SHARE_URL
         * @param {String} queryPart - if included this is appended to the end of the url after a ?
         */
        share_simple(text, hashTags = '', url = '', queryPart = '') {
            url = url === '' ? SHARE_URL : url;
            url = queryPart === '' ? url : url + '?' + queryPart;
            let twSharer = 'https://x.com/intent/tweet?url=' + encodeURIComponent(url) + '&text=' + encodeURIComponent(text);
            if (hashTags !== '') {
                twSharer += '&hashtags=' + encodeURIComponent(hashTags);
            }
            window.open(twSharer, '_blank');
        }
    }
    
    /**
     * Linked in sharing
     */
    class LINKEDIN {
        /**
         * Works identically to twitter sharing, with the addition of a title parameter
         * @param {String} title - message title, defaults to SHARE_TITLE
         * @param {String} text - the message to share, this is our share copy, maybe include the player's score
         * @param {String} hashTags - Twitter supports a multiple hashtags, in a comma seperated list
         * @param {String} url - url to share, defaults to SHARE_URL
         * @param {String} queryPart - if included this is appended to the end of the url after a ?
         */
         share_simple(text, title = '', hashTags = '', url = '', queryPart = '') {
            url = url === '' ? SHARE_URL : url;
            url = queryPart === '' ? url : url + '?' + queryPart;
            title = title === '' ? SHARE_TITLE : title;
            if (hashTags !== '') {
                hashTags = hashTags.split(' ,').join(' #').split(',').join(' #');
                text = text + hashTags;
            }
            let inSharer = 'https://www.linkedin.com/shareArticle?mini=true&url' + encodeURIComponent(url) + '&title=' + encodeURIComponent(title) + '&summary=' + encodeURIComponent(text);
            window.open(inSharer, '_blank');
        }
    }
    
    export const fb = new FB();
    export const tw = new TW();
    export const lin = new LINKEDIN();
    
import './winner-card.css';

import LocationIcon from '../../../uber-assets/location-icon.svg';
import { SHARE_ICON, INSTA_ICON, INFO_ICON } from '../../../uber-assets/inline-svgs';
import Carousel from "react-multi-carousel";
import { CustomDot } from "../carousel/custom-dot";
import { AREAS_BY_ID } from '../../model/areas';

import WinnerRibbon from '../../../uber-assets/large-winner-ribbon.svg';
// 158*157

export function WinnerCard({ icon, category, winner, infoFunction, instagramFunction, orderFunction, isNarrow, showRibbon }) {
    const useImage = winner.winImage || winner.image;
    return (
        <div className={`winner-card${isNarrow ? ' narrow' : ''} card-anim-start`}>
            <div className="winner-card__top-banner">{icon}<p>{category}</p></div>
            <div className='winner-card__content-holder'>
                {!Array.isArray(useImage) &&
                    
                    <img className='winner-card__image' src={`./img/${useImage}`} alt={winner.name} />
                }
                {Array.isArray(useImage) &&
                    <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        customDot={<CustomDot />}
                        arrows={false}
                        responsive={{all: {breakpoint:{min: 0, max: 1000000}, items: 1}}}
                        ssr={false} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={3000}
                        keyBoardControl={false}
                        transitionDuration={500}
                        containerClass=""
                        dotListClass=''
                    >
                        {
                            useImage.map((image, index) => {
                                return (
                                    <div key={index} className="winner-card__image" style={{backgroundImage: `url(./img/${image})`}}></div>
                                )
                            })
                        }
                    </Carousel>
                }
                <div className="winner-card__info">
                
                    <h2 className="winner-card__info__name">{winner.owner || winner.name}</h2>
                    {/* <h3>{winner.restaurant || winner.name}</h3> */}
                    <div className="winner-card__info__location">
                        <img className='small-info-icon' src={LocationIcon} alt='Location icon' />
                        <p>{winner.location || AREAS_BY_ID[winner.region].label}</p>
                    </div>

                    <div className='grow'></div>
                    
                    <div className="winner-card__info__buttons">
                        <button className="winner-card__info__buttons__info square light" onClick={() => infoFunction(winner)}>{INFO_ICON}</button>
                        <button className="winner-card__info__buttons__instagram square light" onClick={() => instagramFunction(winner)}>{INSTA_ICON}</button>
                        <button className="winner-card__info__buttons__order black" onClick={() => orderFunction(winner)}>Order</button>
                    </div>
                </div>
            </div>
            {showRibbon &&
                <img className='card-ribbon' src={WinnerRibbon} alt='Winner' />
            }
        </div>
    );
}
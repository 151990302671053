const API_URL = 'https://ubereatsawards.com/api/'; // 'http://localhost/koko/uber_eats/2024_data/api/'; // './api/'; // 'http://kokodev.co.uk/ubereats/subtest_v1/api/';

export const RECAPTCHA_STATE = {
    LOADING: 'loading',
    READY: 'ready',
    VERIFIED: 'verified',
}

class ApiController {
    PostNomination = (fullName, restaurantName, region, captchaToken, successCallback) => {
        let dataToSubmit = {
            data: {
                full_name: fullName,
                restaurant_name: restaurantName,
                region: region,
                captcha_token: captchaToken,
            }
        }
        // console.log('[API] Submitting data: ', dataToSubmit);
        fetch(API_URL + 'nominate', {
            headers: { "Content-Type": "application/json; charset=utf-8" },
            method: 'POST',
            body: JSON.stringify(dataToSubmit)
        })
            .then(response => response.json())
            .then(data => {/*console.log(data);*/ successCallback(data)});
    }

    FetchRestaurants = (resultCallback) => {
        // console.log('[API] Fetching restaurants...');
        fetch(API_URL + 'fetchrestaurants')
            .then(response => response.json())
            .then(data => {/*console.log(data);*/ resultCallback(data)});
    }

    PostVote = (restaurant_id, token, captchaToken, region_id) => {
        let dataToSubmit = {
            data: {
                rid: restaurant_id,
                region: region_id,
                guid: token,
                captcha_token: captchaToken,
            }
        }
        // console.log('[API] Submitting vote: ', dataToSubmit);
        fetch(API_URL + 'vote', {
            headers: { "Content-Type": "application/json; charset=utf-8" },
            method: 'POST',
            body: JSON.stringify(dataToSubmit)
        })
    }
}

const API_CONTROLLER = new ApiController();
export {API_CONTROLLER as default}
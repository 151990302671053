import { CLOSE_ICON } from '../../../uber-assets/inline-svgs';
import './info-popout.css';
import { useEffect, useRef, useState } from 'react';

export function InfoPopout({ children, closedCallback, otherElementsToSlide }) {
    const popTimout = useRef(null);
    const [popActive, setPopActive] = useState(false);
    const toggleMenu = (forcedState) => {
        if (forcedState === undefined) {
            forcedState = !popActive;
        } else {
            if (forcedState === popActive) {
                return;
            }
        }
        clearTimeout(popTimout.current);
        if (forcedState) {
            popTimout.current = setTimeout(() => {
                setPopActive(true);
                if (otherElementsToSlide && Array.isArray(otherElementsToSlide)) {
                    otherElementsToSlide.forEach(elementId => {
                        const element = document.getElementById(elementId);
                        element.style.left = '0';
                    });
                }
            }, 10);
            window.document.body.style.overflowY = 'hidden';
        } else {
            popTimout.current = setTimeout(() => {
                closedCallback();
            }, 400);
            setPopActive(false);
            if (otherElementsToSlide && Array.isArray(otherElementsToSlide)) {
                otherElementsToSlide.forEach(elementId => {
                    const element = document.getElementById(elementId);
                    element.style.left = '-100vw';
                });
            }
            window.document.body.style.overflowY = 'auto';
        }
    }

    useEffect(() => {
        toggleMenu(true);
    }, []);

    const closePopout = () => {
        toggleMenu(false);
    }

    return (
        <>
            <div className={`info-blackout${popActive ? ' active' : ''}`} onClick={closePopout}></div>
            <div className={`info-popout${popActive ? ' active' : ''}`}>
                {children}
                <div className="close-pop-button" onClick={closePopout}>{CLOSE_ICON}</div>
            </div>
        </>
    );
}

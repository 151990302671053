import { ALL_AREAS } from './areas';

export const GetRestaurantsByRegion = (regionId) => {
    if (regionId === ALL_AREAS.id) {
        return RESTAURANT_SHORTLIST;
    } else {
        return RESTAURANT_SHORTLIST.filter(restaurant => restaurant.region === regionId);
    }
}

const FINALISTS = [
    58, 31, 128, 15, 1, 89, 105, 91, 64, 74, 47, 112
];

export const GetFinalists = () => {
    return RESTAURANT_SHORTLIST.filter(restaurant => FINALISTS.indexOf(restaurant.id) !== -1);
}

const winners = [105];

export const GetWinners = () => {
    return RESTAURANT_SHORTLIST.filter(restaurant => winners.indexOf(restaurant.id) !== -1);
}

export const RESTAURANT_SHORTLIST = [
    {
        id: 1,
        name: 'Locke Burger', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'i',
        instagram: 'https://www.instagram.com/lockeburger/?hl=en',
        description: `Locke Burger was founded in 2018. 
        
        Locke Burger started out serving delicious burgers from our vintage Citroёn food truck. 
        
        We experienced phenomal demand and support from our loyal customers, so in June 2020 we took the next step and moved Locke Burger into a new home at No 1 George's Quay, Limerick and have since expanded to three locations!`,
        image: ['i/1/1.jpg', ],
        winImage: '2023-winners/roty-winner-2023.jpg',            // Add this for the winner card
        isWinner: false,
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/store/locke-burger-castletroy/mF2q1GvwS4ihSlcJHNzTnw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
        orderlink: 'https://www.ubereats.com/store/locke-burger-castletroy/mF2q1GvwS4ihSlcJHNzTnw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
    },
    {
        id: 2,
        name: 'Griolladh', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'i',
        instagram: 'https://www.instagram.com/griolladh/?hl=en',
        description: `Griolladh, pronounced (Grill - Ah), is an Irish Quick Service Restaurant that specialises in customisable toasties. 
        
        Griolladh wants to be the go to for Fast Irish Comfort Food. 
        
        Now with locations in Dublin and Cork and ever growing social following of over 28k!`,
        image: ['i/2/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/store/griolladh-thomas-street/naMMCLTUWH2ONIOSBrfiqg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
        orderlink: 'https://www.ubereats.com/store/griolladh-thomas-street/naMMCLTUWH2ONIOSBrfiqg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
    },
    {
        id: 3,
        name: 'Cluck Chicken', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'i',
        instagram: 'https://www.instagram.com/cluckchicken_truck/?hl=en',
        description: `It all started when Chef Ian Ussher hatched his bright-yellow food truck in late autumn of 2020 at the Walkinstown Roundabout in Dublin 12. 
        
        His sticky Korean wings "Hot Clucker" buffalo-fried buttermilk chicken, truffle Parmesan tenders and loaded fries have had hens, chicks and cockerels flapping around with delight. 
        
        Fast forward to today with 2 brick and mortar locations, one in Tallaght and Walkinstownm, Dublin!`,
        image: ['i/3/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/store/cluck-chicken-tallaght/YQrKLD8pXrmuIzW09YEyig?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
        orderlink: 'https://www.ubereats.com/store/cluck-chicken-tallaght/YQrKLD8pXrmuIzW09YEyig?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
    },
    {
        id: 4,
        name: 'Full Moon Thai', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'i',
        instagram: 'https://www.instagram.com/fullmoonthaidublin/?hl=en',
        description: `Full Moon Thai is all about bringing you fresh authentic Thai food with a relaxed and friendly vibe. 
        
        Our team of Thai chefs freshly prepare all of our dishes to order including all your Thai favourites and some classic authentic Thai dishes.`,
        image: ['i/4/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/store/full-moon-thai-restaurant-temple-bar/E6ymE4oEV5qzBCb7rs1mxw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
        orderlink: 'https://www.ubereats.com/store/full-moon-thai-restaurant-temple-bar/E6ymE4oEV5qzBCb7rs1mxw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
    },
    {
        id: 5,
        name: 'Dosa Dosa', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'i',
        instagram: 'https://www.instagram.com/dosadosadublin/?hl=en',
        description: `DosaDosa owner Karthik Thiru is proud to showcase delicious dishes from his home region of Tamil Nadu, South India. 
        
        DosaDosa's award-winning street food is served only one way........authentic, just like on the streets of South India. 
        
        Whatever you try from our delicious menu you can be assured it is made with the freshest of ingredients to truly traditional recipes.`,
        image: ['i/5/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/store/dosa-dosa-%40-the-place-grand-canal/b355mwH6SbCyoPI3n932uw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
        orderlink: 'https://www.ubereats.com/store/dosa-dosa-%40-the-place-grand-canal/b355mwH6SbCyoPI3n932uw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
    },
    {
        id: 6,
        name: 'Tula Mexican Grill', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'i',
        instagram: 'https://www.instagram.com/tulamexicangrill/?hl=en',
        description: `At Tula, our mission is to bring joy to every customer with our fresh, fast, and delicious food. 
        
        We do that by making our burritos and bowls with only the finest ingredients. 
        
        The provenance of our food is of the utmost importance to us. 
        
        We know that when you make good food with high-quality, fresh, and ethically sourced ingredients, something magical happens. 
        
        We also believe that good food is as much about community as it about ingredients. 
        
        We are committed to doing right by the environment and the people in our communities, which we achieve by using recyclable material where possible, donating a portion of profits to good causes, and incorporating sustainability into our strategic thinking and everyday operations.`,
        image: ['i/6/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/store/tula-mexican-grill-essex-st/ko-kOlsbX2GSGua25Sdx0Q?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
        orderlink: 'https://www.ubereats.com/store/tula-mexican-grill-essex-st/ko-kOlsbX2GSGua25Sdx0Q?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
    },
    /* {
        id: 7,
        name: 'Umi Falafel', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'i',
        instagram: 'https://www.instagram.com/umifalafel/?hl=en',
        description: `Umi means "my mother" in Arabic, because we put as much love and care into our food as your mother would! 
        
        We are strong believers in making healthy, delicious and affordable food for today's busy and health-conscious customer. 
        
        Our falafel mix is prepared for you every morning seven days a week it contains market-fresh produce, fourteen [magic] ingredients that creates the y-umi goodness. 
        
        We make all our sauces fresh daily in-house, our bread is freshly baked and our pickles, sauces, salads are made to a family authentic recipe and chopped fresh throughout the day in our pure veg kitchen. 
        
        Our menu is 100% Vegetarian`,
        image: ['i/7/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/store/umi-falafel-georges-st-arcade/5ZOkBZ-BQo-uMCMFm1cvAA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
        orderlink: 'https://www.ubereats.com/store/umi-falafel-georges-st-arcade/5ZOkBZ-BQo-uMCMFm1cvAA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
    }, */
    {
        id: 8,
        name: 'Smokin\' Bones', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'i',
        instagram: 'https://www.instagram.com/smokinbones_ie/?hl=en',
        description: `Smokin Bones grew from a stall at Herbert Park Barbecue festival in 2014, we now serve you the very same authentic ribs, pulled pork and brisket as you had at The Big Grill. 
        
        At Smokin Bones we source the best possible local ingredients and don't fuss with them too much. 
        
        Simply cooking with wood and time, "Low n' Slow".`,
        image: ['i/8/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/store/smokin-bones-castle-market/-_bB7zneRfatYCegy6XrfA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
        orderlink: 'https://www.ubereats.com/store/smokin-bones-castle-market/-_bB7zneRfatYCegy6XrfA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
    },
    {
        id: 9,
        name: 'Woodfire & Wings', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'i',
        instagram: 'https://www.instagram.com/woodfireandwings/?hl=en',
        description: `Woodfire & Wings combines a fast, casual dining experience with restaurant quality pizza and wings. 
        
        Our food can be delivered straight to your door or for you to grab & go. We only use the freshest of ingredients, the best of Irish and Italian meats and as much locally sourced produce as possible to bring the tastiest flavours and food together! 
        
        All our wings are from big bad-ass Irish chickens from the countryside and we promise you that.We hand-roll our dough daily and have a 48-hour fermentation process so the dough is light, crispy and easy to digest so you can save room for the wings! 
        
        To keep our food tasting fresh and flavourful, we roast all our own vegetables in-house, we roast and glaze our Irish hams so they are super succulent and tasty and we smoke the finest of Irish chicken breast on our own smoker which produces an incredible juicy and balanced flavour for our pizzas.`,
        image: ['i/9/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/store/woodfire-%26-wings-clondalkin/q-DPXBPBVXqh-NnA00kwXQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
        orderlink: 'https://www.ubereats.com/store/woodfire-%26-wings-clondalkin/q-DPXBPBVXqh-NnA00kwXQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
    },
    {
        id: 10,
        name: 'Urban Health', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'i',
        instagram: 'https://www.instagram.com/urbanhealth_ie/?hl=en',
        description: `Situated in the heart of Ranelagh, our cafe & juice bar offers a full brunch menu, including vegan friendly options, 7 days a week. We have a wide variety of smoothies & juices, 'grab & go' salads and wraps, vegan treats and health food products. 
        
        We believe that healthy eating has a positive impact on all areas of life including mind, body and soul. 
        
        We also believe that if everyone in the world reduced their consumption of animal products and swapped them for vegan alternatives, it would have a huge impact on global health, the environment and climate change. 
        
        We continuously strive to create healthy menu options that are environmentally friendly and that reduce animal use. 
        
        We are a 95% zero general waste business, the majority of our packaging is compostable and what's not compostable is recyclable. 
        
        We believe greatly in supporting other small businesses and stock a variety of Irish products. 
        
        Our suppliers are Irish and our ingredients are sourced locally where possible. `,
        image: ['i/10/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/store/urban-health-ranelagh/tIVWYXUsUcW7dA4C3FngQw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
        orderlink: 'https://www.ubereats.com/store/urban-health-ranelagh/tIVWYXUsUcW7dA4C3FngQw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlByb2plY3RzJTIwQmVhY2glMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKRlFFcmxKdUZkVWdSM2ZhYTA3UUJZWjAlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTAuODIwOTIzNCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0wLjE0MjIzMzMlN0Q%3D&utm_source=wok',
    },
    {
        id: 11,
        name: 'Seoul Bird', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/seoulbirduk/?hl=en',
        description: `Seoul Bird was founded by Judy Joo and Andrew Hales, seasoned restaurateurs who have collaborated for over a decade, crafting innovative dining experiences in London and beyond. 
        
        Drawing inspiration from Judy's Korean-American roots and the pair\'s culinary adventures across South Korea, Seoul Bird is a celebration of authentic Korean cooking.

At Seoul Bird, everything is made to order, using only the finest ingredients sourced from trusted suppliers. 

Their commitment to quality is reflected in their use of fresh, high-welfare, halal-certified chicken, and in-house, hand-crafted sauces and batters. 

The result is their signature Korean fried chicken--double-fried to perfection with an irresistibly crispy crust and juicy, flavorful interior. 

Seoul Bird is more than just a meal; it\'s a taste of Korea's vibrant food culture, served with passion and precision.`,
        image: ['l/1/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/seoul-bird-canary-wharf/pVTDsFa2UTydsvB-pDNKvg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/seoul-bird-canary-wharf/pVTDsFa2UTydsvB-pDNKvg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D&ps=1',
    },
    {
        id: 12,
        name: 'The Regency Club', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/theregencyclub/?hl=en',
        description: `Themed after a community members club in Kenya. 
        
        The fusion of Indian spices and influences of Kenyan cooking techniques makes The Regency Club, a family business, one of the finest Bar and Grills around London.`,
        image: ['l/2/1.jpg', 'l/2/2.jpg', 'l/2/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/the-regency-club/kg7FY8W2Qnun4-hProIFgw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/the-regency-club/kg7FY8W2Qnun4-hProIFgw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
    },
    {
        id: 13,
        name: 'Pittagoras - Greek Gyros & Souvlaki', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/pittagorascouk/',
        description: `Introducing Pittagoras, an established joint in Tooting Broadway Market and Hackney Wick (Colour Factory) serving Gyros and Souvlaki the right way. 
        
        Our Gyros is on the spit and freshly shaved, exactly as you'd have it in Greece! 
        
        Souvlakis are freshly made using a family recipe: co-founder Ilias comes from Kefalonia (Greek island) and is the 3rd generation of a local-famous family of restaurateurs. 
        
        His grandfather was the first to bring souvlaki to the island back in 1958, and Pittagoras uses the same recipes they still use in the restaurant on the island!`,
        image: ['l/3/1.jpg', 'l/3/2.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/pittagoras-greek-gyros-%26-souvlaki/Ujc4SA6cU1WJDLZZFmq_0Q?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/pittagoras-greek-gyros-%26-souvlaki/Ujc4SA6cU1WJDLZZFmq_0Q?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
    },
    {
        id: 14,
        name: 'Lahore Karahi', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/lahorekarahitooting/?hl=en',
        description: `Established in 1995, Lahore Karahi is a family-run restaurant serving authentic Pakistani cuisine. 
        
        Despite the complexity of our dishes, our recipe for a great evening is simple: mouth-watering food, a lively atmosphere and close friends.`,
        image: ['l/4/1.jpg', 'l/4/2.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/lahore-karahi/2HllneE-RBKyGa2rBreZyQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/lahore-karahi/2HllneE-RBKyGa2rBreZyQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
    },
    {
        id: 15,
        name: 'Junglees', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/jungleesburgers/?hl=en',
        description: `Established in 2021, Junglees quickly gained a reputation for its bold flavors and unique concept. 
        
        Founded by Nomaan, who left a corporate law career to pursue his passion for food, Junglees delivers an unforgettable dining experience that resonates with every guest. 
        
        The rapid success of their first location led to the opening of a second site in 2023, further solidifying their presence in the culinary scene. 
        
        Junglees\' unwavering commitment to quality and freshness is evident in their use of the finest ingredients, ensuring each dish is crafted to perfection. 
        
        But it's not just their food that sets them apart; they have cultivated a warm, family-friendly atmosphere, complete with a unique interior and a team of welcoming staff, making every visit to Junglees a memorable one.`,
        image: ['l/5/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/junglees-barkingside/sLykfaUyWnClUMf7b9lQzA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/junglees-barkingside/sLykfaUyWnClUMf7b9lQzA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
    },
    {
        id: 16,
        name: 'Halo Burger', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/haloburgeruk/?hl=en',
        description: `Halo Burger is dedicated to proving that delicious burgers can be enjoyed without harming the planet. 
        
        Committed to sustainability, they prioritise sourcing 100% plant-based ingredients and actively work to minimise their environmental impact`,
        image: ['l/6/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/halo-burger-hackney-bridge/MnM6wuskUXOw_74L9ynCYw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/halo-burger-hackney-bridge/MnM6wuskUXOw_74L9ynCYw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
    },
    {
        id: 17,
        name: 'Smoke & Pepper Bow', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/smokeandpepperldn/',
        description: `Smoke & Pepper was developed from a busy family kitchen having a desire to serve the highest quality comfort food being affordable to all. 
        
        Then, new burger joint has now turned into a raved-about restaurant in the heart of Leyton due to our passion for food and creative cooking. 
        
        To find that perfect burger, days and nights were spent tasting recipes and sourcing ingredients thus, securing our love for grass-fed Aberdeen Angus beef. 
        
        Using freshly ground 100% dry aged beef to craft the delicious juicy burgers we have on offer. 
        
        From the beginning we have attracted social foodies from all over Instagram and TikTok.  
        
        The success thereon followed when we opened in Leyton in 2022 and now onto our newly curated hybrid of a takeaway-meets-restaurant feels, within the popular Bow arches for 2024.

The journey is a personal one. As co-founders, our commitment runs deep, fostering connections with local farmers for responsibly sourced ingredients. `,
        image: ['l/7/1.jpg', 'l/7/2.jpg', 'l/7/3.jpg', 'l/7/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/smoke-%26-pepper-bow/CKERs5jqWyCOcj5-u_sw-A?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/smoke-%26-pepper-bow/CKERs5jqWyCOcj5-u_sw-A?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
    },
    {
        id: 18,
        name: 'Kome Japanese Sushi', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/komeexpress_southwoodford/',
        description: `Kome Japanese Sushi delivers traditional flavors with a modern twist. 
        
        They use fresh ingredients, from sushi-grade fish to seasonal produce, ensuring every dish is a cultivated with care. 
        
        Whether you\'re enjoying their expertly crafted sushi or flavorful katsu at home, their commitment to quality and authenticity shines through. 
        
        Their wonderful team, passionate about creating exceptional dishes, ensures every meal is made with care and dedication.`,
        image: ['l/8/1.jpg', 'l/8/2.jpg', 'l/8/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/%E7%B1%B3-kome-japanese-sushi/P43d9tB5TkaHxKmyvO8M5A?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/%E7%B1%B3-kome-japanese-sushi/P43d9tB5TkaHxKmyvO8M5A?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
    },
    {
        id: 19,
        name: 'Five Lads', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/fiveladsleyton/',
        description: `Five Lads first opened its door back in early 2015 bringing an exciting new street food brand to the east end of London, providing a fast-casual service that expertly balances convenience with quality. 
        
        Delicious meals are made-to-order and can be enjoyed in the upscale urban restaurants or picked up by customers looking to take-away. 
        
        Five Lads has introduced an exciting menu that uses the finest fresh ingredients to ensure the quality is consistent every time. 
        
        Together with our signature Peri Peri chicken, marinated in our secret recipe along with a choice of Gourmet burgers, there is something for everyone.

Before our chicken is served, its goes through a vigorous cooking process to make sure it's just right. 

It is marinated for 24 hours, which allows all the flavours to penetrate and is then cooked to perfection. 

It is finished with a choice of our unique flavours.`,
        image: ['l/9/1.jpg', 'l/9/2.jpg', 'l/9/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/five-lads-leyton/Y89DWztQQ3yl9NAFfnH9dQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/five-lads-leyton/Y89DWztQQ3yl9NAFfnH9dQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
    },
    {
        id: 20,
        name: 'Kervan Sofrasi Restaurant', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/kervansofrasi_/?hl=en',
        description: `Kervan Sofrasi is a well-regarded Turkish restaurant chain with several locations across London, including Hertford Road, Church Street, Southgate, and Wood Green. 
        
        The restaurant is known for its authentic Middle Eastern cuisine, particularly traditional Turkish dishes, which are prepared with high-quality ingredients. 
        
        Kervan Sofrasi is ideal for those looking to experience rich and flavorful Turkish culinary traditions in a comfortable setting.`,
        image: ['l/10/1.jpg', 'l/10/2.jpg', 'l/10/3.jpg', 'l/10/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/kervan-sofrasi-restaurant-wood-green/HdQkwji3QQKev06mRYywYQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/kervan-sofrasi-restaurant-wood-green/HdQkwji3QQKev06mRYywYQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
    },
    {
        id: 21,
        name: 'Alma Cafe', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/almacafelondon/?hl=en',
        description: `Alma Cafe is a neighborhood favourite, celebrated for its wholesome brunches, quality smoothies, and well-balanced healthy lunches, all made with the freshest ingredients. 
        
        With its warm, inviting atmosphere, Alma Cafe is where the community comes together to enjoy nourishing food and good company. 
        
        Whether you're grabbing breakfast or lunch, you'll find plenty of nutritious and healthy choices that don't compromise on flavour. 
        
        It's a great spot if you're looking to eat well without the fuss.`,
        image: ['l/11/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/alma-barnes/VrS8MCqkQlCNMiu3240BwA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/alma-barnes/VrS8MCqkQlCNMiu3240BwA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
    },
    {
        id: 22,
        name: 'Grill Cartel', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/grill.cartel/reels/',
        description: `Grill Cartel in London's Uxbridge offers an Organic Fusion experience to its customers, highlighted by its popular Signature Lamb Doner Plate and a variety of shawarma options including plates and wraps. 
        
        The site also boasts a selection of organic burgers, such as the Cartel Philly Cheese Steak Burger, and a diverse range of teas including Cartel Masala Karak and Cartel Ginger Karak. 
        
        We pride ourself on offering the finest ingredients such as Beltex Lamb and Angus Beef. 
        
        It's no surprise, therefore, that only in 5 or so years, we've risen to one of Uxbridge's biggest and boldest brands and one of the most reputable names in the area, with plans to expand all across the UK and Internationally.`,
        image: ['l/12/1.jpeg', 'l/12/2.jpeg', 'l/12/3.jpeg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/grill-cartel/xh06602WR4mUHd9imkyZXA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/grill-cartel/xh06602WR4mUHd9imkyZXA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D',
    },
    {
        id: 23,
        name: 'EAT LEBÖ', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/eatlebo/',
        description: `Inspired by the nomads of Lebanon, EAT LEBÖ is an urban Lebanese street food restaurant that believes our health is shaped by what we eat and our mood by how good it tastes. 
        
        With 42 vegan and vegetarian options, 18 grilled meats, 29 gluten-free dishes, and 30 dairy-free delights, EAT LEBÖ caters to everyone. 
        
        Whether you're health-conscious or in the mood for an all-out cheat day, the menu has you covered. 
        
        Each dish, from vibrant Mezze's to their famous Gym Bowls, offers a perfect blend of authenticity and indulgence. 
        
        LEBÖ stands for 'LOVE EVERY BITE' with the 'Ö' repping a mouth wide open.`,
        image: ['l/13/1.jpg', 'l/13/2.jpg', 'l/13/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/eat-lebo-ruislip/8pUZi9G1Qj29HOVBhebXfw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/eat-lebo-ruislip/8pUZi9G1Qj29HOVBhebXfw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
    },
    {
        id: 24,
        name: 'Detroit Pizza', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/detroitpizzalondon/?hl=en',
        description: `In 2020, when London - a global food capital - locked down, Detroit Pizza London came to life. 
        
        The concept was born out of necessity. 
        
        During this time of uncertainty and confusion, we had a nostalgic feeling, a yearning for home... 
        
        And that's how Detroit Pizza London started. 
        
        When we first opened, Detroit Pizza was trading out of a literal hole-in-the-wall in a Network Rail graveyard in Battersea. 
        
        Customers picked up their orders through a dark sketchy alleyway, but still, after a few weeks, queues started to form. 
        
        People were coming for their fix of the most unique pizza in town. 
        
        At Detroit Pizza, we're about 3 things. 
        
        And that's quality ingredients, serving an amazing product, and going the extra mile for our customers.`,
        image: ['l/14/1.jpg', 'l/14/2.jpg', 'l/14/3.jpg', 'l/14/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/detroit-pizza/VcmwPr8sSwe_o_YgffH4ag?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522VcmwPr8sSwe_o_YgffH4ag%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/detroit-pizza/VcmwPr8sSwe_o_YgffH4ag?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522VcmwPr8sSwe_o_YgffH4ag%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D&ps=1',
    },
    {
        id: 25,
        name: 'Daakoo', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/daakoo_indianfood/?hl=en-gb',
        description: `Daakoo uses the finest quality, fresh and authentic ingredients which are creatively combined by their chefs to balance the flavours of their exquisite dishes. 
        
        With a new second location having opened this summer in Ladbroke Grove Daakoo are sharing their incredible food with more and more people across London. `,
        image: ['l/15/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/daakoo-ladbroke-grove/VNDOUg5yXgyDRrxKO342tg?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522VNDOUg5yXgyDRrxKO342tg%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/daakoo-ladbroke-grove/VNDOUg5yXgyDRrxKO342tg?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522VNDOUg5yXgyDRrxKO342tg%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D&ps=1',
    },
    {
        id: 26,
        name: 'Wingmans', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/wingmanschicken/?hl=en',
        description: `Wingmans is a multi-award-winning independent restaurant group, awards include Best of the Best at the British Street Food Awards and numerous Wingfest awards. 
        
        Specialising in Homemade American-style comfort foods with an emphasis on everything chicken- specifically the sauciest wings known to mankind! `,
        image: ['l/16/1.jpg', 'l/16/2.jpg', 'l/16/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/wingmans-soho/U3n9xdcFQVime2AUvQlMDg?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522U3n9xdcFQVime2AUvQlMDg%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/wingmans-soho/U3n9xdcFQVime2AUvQlMDg?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522U3n9xdcFQVime2AUvQlMDg%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D&ps=1',
    },
    {
        id: 27,
        name: 'Roti King', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/rotikinguk/?hl=en',
        description: `Roti King brings the vibrant flavours of Malaysia and Singapore to London, blending Malay, Chinese, and Indian influences into every dish. 
        
        As a multi-award winning, Halal restaurant, Roti King has earned its spot among the restaurant critic's top 10 UK restaurants. 
        
        With nearly 50k Instagram followers, this culinary gem has quickly become a must-visit destination for food lovers in London. `,
        image: ['l/17/1.jpg', 'l/17/2.jpg', 'l/17/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/roti-king-canary-wharf/eVdlrvTGVT-6h36g06u9-g?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/roti-king-canary-wharf/eVdlrvTGVT-6h36g06u9-g?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
    },
    {
        id: 28,
        name: 'Maido Sushi', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/maidosushi_/?hl=en',
        description: `Maido Sushi has quickly become a favorite destination for sushi enthusiasts and food lovers. 
        
        Known for its premium quality handcrafted sushi, exceptional flavors, and impeccable presentation, Maido Sushi offers an authentic Japanese dining experience with a contemporary twist. 
        
        Our menu blends traditional Japanese techniques with innovative culinary artistry, using the freshest ingredients sourced locally and internationally. 
        
        Each dish reflects our commitment to quality and craftsmanship. 
        
        With a sophisticated yet welcoming atmosphere, Maido Sushi is the perfect choice for both special occasions and casual dining, promising an unforgettable experience that celebrates the essence of Japanese cuisine`,
        image: ['l/18/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/maido-sushi-st-johns-wood/OlqUkpJaR1uzr0QXyYZfLw?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522OlqUkpJaR1uzr0QXyYZfLw%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/maido-sushi-st-johns-wood/OlqUkpJaR1uzr0QXyYZfLw?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522OlqUkpJaR1uzr0QXyYZfLw%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D&ps=1',
    },
    {
        id: 29,
        name: 'Tofu Vegan', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/tofuveganlondon/?hl=en',
        description: `At Tofu Vegan, we are proud to offer a completely plant-based menu which is suitable for vegetarians and vegans, and will also delight those who usually eat meat. 
        
        Many of our dishes are made with tofu and mushrooms, or with Asian imitation meats made from bean proteins and other meat-free ingredients. 
        
        Our expert team includes chefs from Sichuan, the Cantonese south of China and the northeastern Dongbei region, so the menu features a range of enticing regional flavours.`,
        image: ['l/19/1.jpg', 'l/19/2.jpg', 'l/19/3.jpg', 'l/19/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/tofu-vegan-angel/_IuNZPeySoCFYPDxBepDkw?chain_slug=tofu-vegan&diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522_IuNZPeySoCFYPDxBepDkw%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/tofu-vegan-angel/_IuNZPeySoCFYPDxBepDkw?chain_slug=tofu-vegan&diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522_IuNZPeySoCFYPDxBepDkw%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D&ps=1',
    },
    {
        id: 30,
        name: 'HOKO', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'l',
        instagram: 'https://www.instagram.com/hoko.london/?hl=en-gb',
        description: `Hoko is a cozy cafe on Brick Lane, London, inspired by Hong Kong's classic cha chaan tengs--simple, comforting eateries known for sweet, deep-fried, and savory dishes. 
        
        With 30k Instagram followers, Hoko has quickly become a beloved spot, offering dishes like classic Hong Kong milk tea, their famous french toast, and pork chop rice with egg. 
        
        The cafe's unpretentious vibe, with wood paneling and Canto-pop, perfectly captures the soul of cha chaan teng cuisine. 
        
        Founder Nicole Ma stays true to the authentic, comforting flavors, making Hoko a must-visit for nostalgic and delicious Hong Kong food.`,
        image: ['l/20/1.jpg', 'l/20/2.jpg', 'l/20/3.jpg', 'l/20/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/hoko-cafe-%E9%A6%99%E6%B8%AF%E5%86%B0%E5%BB%B3/nJ_GBnoTWlGJbbjGI1FUcA?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522nJ_GBnoTWlGJbbjGI1FUcA%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/hoko-cafe-%E9%A6%99%E6%B8%AF%E5%86%B0%E5%BB%B3/nJ_GBnoTWlGJbbjGI1FUcA?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522nJ_GBnoTWlGJbbjGI1FUcA%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D&ps=1',
    },
    {
        id: 31,
        name: 'Chick and Shakes', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'mid',
        instagram: 'https://www.instagram.com/chickandshakes/?hl=en',
        description: `Chicken and desserts worth crossing the road for! 
        
        Chick and Shakes is Nottingham\'s favourite chicken restaurant, new to the scene in April 2023 and taking the market by storm. 
        
        From amazing chick burgers, wrap, tenders and wings, to shakes, secret sodas, waffles, donuts and churros, there\'s a chick for everyone. `,
        image: ['mid/1/1.jpg', 'mid/1/2.jpg', 'mid/1/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/chick-and-shakes/Vf5fIYlNUtOuaENJy6j_sQ?diningMode=PICKUP&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlViZXIlMjAtJTIwR3JlZW5saWdodCUyMEh1YiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMjUxMjk5MjQzLTEzNGMtYzljZS05ZTEzLWQzMzE1OWYwMDFjMyUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJ1YmVyX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU1OTA2NCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE0NDY3MjMlN0Q%3D&utm_campaign=CM2508147-search-free-nonbrand-google-pas_e_all_acq_Global&utm_medium=search-free-nonbrand&utm_source=google-pas',
        orderlink: 'https://www.ubereats.com/gb/store/chick-and-shakes/Vf5fIYlNUtOuaENJy6j_sQ?diningMode=PICKUP&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlViZXIlMjAtJTIwR3JlZW5saWdodCUyMEh1YiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMjUxMjk5MjQzLTEzNGMtYzljZS05ZTEzLWQzMzE1OWYwMDFjMyUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJ1YmVyX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU1OTA2NCUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE0NDY3MjMlN0Q%3D&utm_campaign=CM2508147-search-free-nonbrand-google-pas_e_all_acq_Global&utm_medium=search-free-nonbrand&utm_source=google-pas',
    },
    {
        id: 32,
        name: 'Tipu Sultan Nottingham', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'mid',
        instagram: 'https://www.instagram.com/tipusultannottingham/?hl=en-gb',
        description: `Tipu Sultan serves the finest of Indian, Asian, Grill, Balti and Pakistani cuisine. 
        
        The restaurant is the perfect for couples, families and friends, whether for business, socialising, getting together, or ordering your favourite Indian-Pakistani foods to your door, relax and enjoy the best food and drink, and live like a Sultan.`,
        image: ['mid/2/1.jpg', 'mid/2/2.jpg', 'mid/2/3.jpg', 'mid/2/4.jpg', 'mid/2/5.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/tipu-sultan-nottingham/-W1vxZaWXmSCk6nkCTbazQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/tipu-sultan-nottingham/-W1vxZaWXmSCk6nkCTbazQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxvbmRvbiUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpkZDRocnd1ZzJFY1JtU3JWM1ZvNmxsSSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1MS41MDcyMTc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTAuMTI3NTg2MiU3RA%3D%3D',
    },
    {
        id: 33,
        name: 'Four Seasons Hot Pot 四季火锅', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'mid',
        instagram: 'https://www.facebook.com/profile.php?id=100088888706393&paipv=0&eav=Afa9YP28_nvWy5IpBmA-ykrSoxJ3N-HcfiyCQuIYeuhbYg0j60mJQuAV3jTB-scmZio',
        description: `Four Seasons Hot Pot 四季火锅 is the top choice for authentic Chinese hot pot lovers. 
        
        Offering a wide range of fresh ingredients and flavorful broths, this spot lets you create the perfect hot pot experience every time. 
        
        Ideal for sharing with friends or family, it\'s a must-visit for anyone craving a comforting, communal meal.`,
        image: ['mid/3/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/four-seasons-hot-pot-%E5%9B%9B%E5%AD%A3%E7%81%AB%E9%94%85/UCvClACtX46qXDY9zNXBBA',
        orderlink: 'https://www.ubereats.com/gb/store/four-seasons-hot-pot-%E5%9B%9B%E5%AD%A3%E7%81%AB%E9%94%85/UCvClACtX46qXDY9zNXBBA',
    },
    {
        id: 34,
        name: 'Hotville', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'mid',
        instagram: 'https://www.instagram.com/hotvilleuk/?hl=en',
        description: `Hotville is a vibrant food brand that specializes in serving authentic Nashville-style fried chicken. 
        
        Originating from Leicester and expanding across the UK, Hotville is known for its juicy, succulent fried chicken, particularly the signature Hotville Burger. 
        
        The brand prides itself on delivering a genuine Nashville food experience, blending Southern flavors with a modern twist, and is growing in popularity throughout the UK.`,
        image: ['mid/4/1.jpg', 'mid/4/2.jpg', 'mid/4/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/hotville-uppingham-road/krO2RJL3V5WGeQO8EZK-YQ?chain_slug=hotville&diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxlaWNlc3RlciUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpjMnkzU2F0Q2QwZ1J1SnkwYnlvZEZabyUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1Mi42MzY4Nzc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTEuMTM5NzU5MiU3RA%3D%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/hotville-uppingham-road/krO2RJL3V5WGeQO8EZK-YQ?chain_slug=hotville&diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkxlaWNlc3RlciUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpjMnkzU2F0Q2QwZ1J1SnkwYnlvZEZabyUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1Mi42MzY4Nzc4JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTEuMTM5NzU5MiU3RA%3D%3D&ps=1',
    },
    {
        id: 35,
        name: 'Spicy Wok Express', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'mid',
        instagram: 'https://www.instagram.com/spicywok_express/',
        description: `Unique, Tasty, Clean, Quick and 100% Halal.`,
        image: ['mid/5/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/spicy-wok-express/Q6GguASsXQWORRBQQ6tcOg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/spicy-wok-express/Q6GguASsXQWORRBQQ6tcOg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
    },
    {
        id: 36,
        name: 'Chinese Snack Box 老家面馆', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'mid',
        instagram: '',
        description: `Authentic Chinese street food in Leicester! 
        
        Chinese Snack Box 老家面馆 is the city\'s favorite for handmade noodles, dumplings, and savory snacks. 
        
        Bringing the vibrant flavors of China to your doorstep, this hidden gem offers a delicious, quick bite or a feast of traditional favorites.`,
        image: ['mid/6/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/chinese-snack-box-%E8%80%81%E5%AE%B6%E9%9D%A2%E9%A6%86/4osBR66EQ_igoifxrp5W_Q?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/chinese-snack-box-%E8%80%81%E5%AE%B6%E9%9D%A2%E9%A6%86/4osBR66EQ_igoifxrp5W_Q?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
    },
    {
        id: 37,
        name: 'Monmo\'s Derby', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'mid',
        instagram: 'https://www.instagram.com/monmos_derby/',
        description: `House made marinated chicken cooked on a blacktop offering a wide variety of flavours and spices for every occasion! 
        
        Since 2013 Monmo\'s has been a pillar of the community offering top of the range flavours at affordable prices.`,
        image: ['mid/7/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/monmos-derby/6S4E9JR8RmCnqT65RqclVA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/monmos-derby/6S4E9JR8RmCnqT65RqclVA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
    },
    {
        id: 38,
        name: 'Gohan Bento & Sushi', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'mid',
        instagram: 'https://www.instagram.com/gohan_bentosushi/',
        description: `Sushi and bento worth the journey! 
        
        Gohan Bento and Sushi Shop is Loughborough\'s go-to spot for fresh and authentic Japanese cuisine. 
        
        Since opening its doors, Gohan has quickly become a local favorite, offering a mouthwatering selection of sushi rolls, bento boxes, and traditional Japanese dishes. 
        
        From signature sushi rolls to delicious ramen and tempura, there\'s a taste of Japan for everyone at Gohan. Whether you\'re craving a quick lunch or a satisfying dinner, Gohan is the place to be.`,
        image: ['mid/8/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/gohan-bento-%26-sushi/vzXOE2PdUI6JGasOtbeMAw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/gohan-bento-%26-sushi/vzXOE2PdUI6JGasOtbeMAw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
    },
    {
        id: 39,
        name: 'Crunch', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'mid',
        instagram: 'https://www.instagram.com/crunchfooduk/?hl=en',
        description: `The healthiest type of fast food - Crunch embraces the colourful flavors of summer with their fresh and delicious crunch salads, packed with protein and tasty extras. 
        
        It's not just about providing healthy options; it's about creating a community that values wellness and supports each other's journey towards a better lifestyle.`,
        image: ['mid/9/1.jpg', 'mid/9/2.jpg', 'mid/9/3.jpg', 'mid/9/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/crunch/k3UFOoeMXkWapyxm2438tg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/crunch/k3UFOoeMXkWapyxm2438tg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
    },
    {
        id: 40,
        name: 'Brick Pizza', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'mid',
        instagram: 'https://www.instagram.com/brickpizza_norwich/?hl=en-gb',
        description: `Brick Pizza, established in 2015 and located in the heart of Norwich, has quickly become a cult favorite for pizza lovers. 
        
        With nearly 20K followers on Instagram, Brick Pizza has gained a loyal fan base by pushing the boundaries of traditional Italian cuisine. Known for its bold and unconventional takes on classic pizzas, Brick Pizza blends creativity and quality in every bite. 
        
        Their passion for innovation doesn't stop there expanding and scaling their business to offer nationwide delivery of their signature frozen pizzas, allowing fans across the UK to enjoy their unique flavors from the comfort of home.`,
        image: ['mid/10/1.jpg', 'mid/10/2.jpg', 'mid/10/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/brick-pizza-ring-road/welkBkO4VeiWDPUnC-etmg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/brick-pizza-ring-road/welkBkO4VeiWDPUnC-etmg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
    },
    {
        id: 41,
        name: 'EKO \'77', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'wm',
        instagram: 'https://www.instagram.com/eko77.uk/?hl=en&img_index=1',
        description: `The name EKO \'77 is derived from the Yoruba word for Lagos, which was the former capital of Nigeria. 
        
        The \'77 in the name refers to FESTAC \'77, the 2nd World Black and African Festival of Arts and Culture held in 1977 in Festac, Lagos. 
        
        Lagos is renowned for its rich and diverse culinary traditions, which have been shaped by the city\'s history and geography. 
        
        By naming her restaurant EKO \'77, Chef Sekinat pays homage to the city and the culture that has inspired her cuisine.`,
        image: ['wm/1/1.jpg', 'wm/1/2.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/eko-77/1B1tgE2mQLGxJK8LHD4tEA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/eko-77/1B1tgE2mQLGxJK8LHD4tEA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
    },
    {
        id: 42,
        name: 'BadBoy Wings', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'wm',
        instagram: 'https://www.instagram.com/badboywings/?hl=en',
        description: `Started out on the street food scene in Birmingham, now they also have a permant home & are serving award winning wings!`,
        image: ['wm/2/1.jpg', 'wm/2/2.jpg', 'wm/2/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/badboy-wings/ZBCb1Cz_X_SxDjhYkjqlrA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/badboy-wings/ZBCb1Cz_X_SxDjhYkjqlrA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
    },
    {
        id: 43,
        name: 'The Early Bird Bakery', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'wm',
        instagram: 'https://www.instagram.com/theearlybirdbakery/?hl=en',
        description: `We are a coffee shop and bakery on the historic Kings Heath High Street in Birmingham, bringing over fifteen years of industry experience to offer delicious and creative bakes and brunch options. 
        
        Our pastries, cakes and savouries are baked daily in-house using the finest and where possible, locally sourced ingredients. 
        
        We work with local roasters Hundred House Coffee to supply the most exciting speciality coffee.`,
        image: ['wm/3/1.jpg', 'wm/3/2.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/the-early-bird-bakery/z6WfebSKWUmjKu7Ip1mREA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/the-early-bird-bakery/z6WfebSKWUmjKu7Ip1mREA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
    },
    {
        id: 44,
        name: 'Authentic Jerk House', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'wm',
        instagram: 'https://www.instagram.com/authenticjerkhouse/?hl=en-gb',
        description: `Local, award winning family run Caribbean restaurant, actively involved with the community, serving up some of the most flavourful, athentic jerk dishes & combo boxes. `,
        image: ['wm/4/1.jpg', 'wm/4/2.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/authentic-jerk-house/0e5gWpnVXk2AoWMIIufNrg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/authentic-jerk-house/0e5gWpnVXk2AoWMIIufNrg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
    },
    {
        id: 45,
        name: 'iso.', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'wm',
        instagram: 'https://www.instagram.com/isosushi/?hl=en',
        description: `iso. is a Japanese Sushi takeaway brand offering authentic cuisine freshly prepared using the finest ingredients. 
        
        We offer delicious sushi dishes using the finest ingredients to exceed all expectations, as well as offering delivery services! 
        
        We pride ourselves in high quality ingredients, great customer service, value for money and most importantly, a taste that will keep you coming back for more.`,
        image: ['wm/5/1.jpg', 'wm/5/2.jpg', 'wm/5/3.jpg', 'wm/5/4.jpg', 'wm/5/5.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/iso/RiZgyLwNVv6Ov3fdbcHGJg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/iso/RiZgyLwNVv6Ov3fdbcHGJg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
    },
    {
        id: 46,
        name: 'Shokupan', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'wm',
        instagram: 'https://www.instagram.com/Shokupanmenu/',
        description: `Shokupan is Your New Breakfast, Brunch, Lunch and full-on Munch.

Shokupan is our thing. We bake fresh Japanese Shokupan bread daily, which creates cheesy sando toasties (hotties) featuring the iconic Hot Pepper Chicken Hottie and authentic Japanese Sandos, including Chicken Katsu. 

We've also upped the Shokupan game with Detroit Steel Pan Style Pizza. 

Once you eat Shokupan you never go back. 

Trust.`,
        image: ['wm/6/1.jpg', 'wm/6/2.jpg', 'wm/6/3.jpg', 'wm/6/4.jpg', 'wm/6/5.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/shokupan/mutwIyGNXEubeRdq7EpOgQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/shokupan/mutwIyGNXEubeRdq7EpOgQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
    },
    {
        id: 47,
        name: 'The Soho Tavern', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'wm',
        instagram: 'https://www.instagram.com/sohotavern/?hl=en-gb',
        description: `Multi Award Winning Bar & Grill, serving premium Indian cuisine. 
        
        The home of the Indian Mix grill.`,
        image: ['wm/7/1.jpg', 'wm/7/2.jpg', 'wm/7/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/the-soho-tavern/FEymVw83TiaDsQHfuCoA7A?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/the-soho-tavern/FEymVw83TiaDsQHfuCoA7A?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
    },
    {
        id: 48,
        name: 'Itaewon', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'wm',
        instagram: 'https://www.instagram.com/itaewonbham/?hl=en',
        description: `A celebration of all things Korean. 
        
        Our menu features traditional Korean dishes and Korean BBQ. 
        
        Inspired by the vibrant Itaewon district in Seoul, we invite you to experience the bold and spicy flavors of Korean cuisine.`,
        image: ['wm/8/1.jpg', 'wm/8/2.jpg', 'wm/8/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/itaewon-korean-restaurant/LDxwkMW5XCC-qPVD5T6HOQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/itaewon-korean-restaurant/LDxwkMW5XCC-qPVD5T6HOQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
    },
    {
        id: 49,
        name: 'Picasso\'s Dessert', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'wm',
        instagram: 'https://www.instagram.com/picassosuk/',
        description: `Picasso\'s, founded in Birmingham in 2017, began with a passion for desserts and a creative vision. 
        
        The brand's mantra is 'everything is art'. 
        
        So, what you see, feel and experience is a lifetime of imagining, innovating and creating. 
        
        We are aspiring artists who express ourselves through the food we serve. 
        
        Picasso's is our gallery. `,
        image: ['wm/9/1.jpg', 'wm/9/2.jpg', 'wm/9/3.jpg', 'wm/9/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/picassos-dessert/2a5Sr34bQJCjty_YYamSGw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/picassos-dessert/2a5Sr34bQJCjty_YYamSGw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
    },
    {
        id: 50,
        name: 'Libertine Burger', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'wm',
        instagram: 'https://www.instagram.com/libertineburger/?hl=en',
        description: `Since bursting on to the street food scene in 2016 serving food from a \'pimped-up\' Renault Master, Libertine Burger has made a name for itself as one of the biggest burger restaurants in the country doling out hearty beef & fried chicken burgers, plus plant-based bites & shakes. 

?As well as our three restaurants in Rugby, Leamington Spa and Stratford-upon-Avon and food trucks that tour the UK\'s biggest festivals, we serve up over a thousand takeaway burgers each week as well as sending our DIY Burger Kits across the country so people can enjoy them in their own homes.

Our mouthwatering patties have led to us reaching the National Burger Awards finals three times, being crowned UK winners in 2019. We've also made it to the finals in the British Street Food Awards, the Street Food Championships and the Just Eat Restaurant Awards in 2023.`,
        image: ['wm/10/1.jpg', 'wm/10/2.jpg', 'wm/10/3.jpg', 'wm/10/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/libertine-burger/-K_Obd6hUZCaAr_153LkNw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/libertine-burger/-K_Obd6hUZCaAr_153LkNw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
    },
    {
        id: 51,
        name: 'Davey\'s UK', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ne',
        instagram: 'https://www.instagram.com/daveys_uk/',
        description: `Davey's burst onto the food scene back in May 2020 and have been serving up Breakfast sandwiches, Burgers and more ever since! 
        
        They are a firm fan favourite in the North East! `,
        image: ['ne/1/1.jpg', 'ne/1/2.jpg', 'ne/1/3.jpg', 'ne/1/4.jpg', 'ne/1/5.jpg', 'ne/1/6.jpg'],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/daveys-uk-sandyford/j-grbcW-Rzygueg1zOV0rw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/daveys-uk-sandyford/j-grbcW-Rzygueg1zOV0rw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5vdHRpbmdoYW0lMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKelhrSE9kSXllRWdSRmRzWkdTQmpnQkElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTIuOTU0MDIyMyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjE1NDk4OTIlN0Q%3D',
    },
    {
        id: 52,
        name: 'My Delhi', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ne',
        instagram: 'https://www.instagram.com/mydelhistreetery/?hl=en',
        description: `My Delhi is a Multi Award winning Indian eatery with branches in Newcastle, Sunderland and Leicester! 
        
        Get ready to be transported to the streets of Delhi!`,
        image: ['ne/2/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/my-delhi-newcastle/1z_aavO1T9qnvWGcsZNLFg?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%25221z_aavO1T9qnvWGcsZNLFg%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5ld2Nhc3RsZSUyMHVwb24lMjBUeW5lJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSnpXUnZESDZGZlVnUmtXR25jckJTNGdzJTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTU0Ljk3ODI1MiUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjYxNzc4JTdE&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/my-delhi-newcastle/1z_aavO1T9qnvWGcsZNLFg?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%25221z_aavO1T9qnvWGcsZNLFg%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5ld2Nhc3RsZSUyMHVwb24lMjBUeW5lJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSnpXUnZESDZGZlVnUmtXR25jckJTNGdzJTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTU0Ljk3ODI1MiUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjYxNzc4JTdE&ps=1',
    },
    {
        id: 53,
        name: 'Dot Bagels', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ne',
        instagram: 'https://www.instagram.com/dotbagels/?hl=en',
        description: `Dot Bagels are a local favourite in the North East, serving freshly baked bagels stuffed with innovative fillings! 
        
        Their Salt on chilli Road bagel has gained a huge following!`,
        image: [/* 'ne/3/1.jpeg',  */'ne/3/2.jpeg', 'ne/3/3.jpeg', 'ne/3/4.jpeg', 'ne/3/5.jpeg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/dot-bagels-chillingham-road/obJUAzavVk6EQEl47TjUZA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/dot-bagels-chillingham-road/obJUAzavVk6EQEl47TjUZA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D',
    },
    {
        id: 54,
        name: 'Pizza Globe', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ne',
        instagram: 'https://www.instagram.com/pizzaglobe/',
        description: `With pizzas taking influences from iconic citites around the world, Pizza Globe are putting a unique twist on the classic Neopolitan Pizza. 
        
        Chips on a pizza? Yes please `,
        image: ['ne/4/1.jpg', 'ne/4/2.jpg', 'ne/4/3.jpg', 'ne/4/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/pizza-globe/dKddaQnpUjqC1RL0ltmf7w?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522dKddaQnpUjqC1RL0ltmf7w%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5ld2Nhc3RsZSUyMHVwb24lMjBUeW5lJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSnpXUnZESDZGZlVnUmtXR25jckJTNGdzJTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTU0Ljk3ODI1MiUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjYxNzc4JTdE&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/pizza-globe/dKddaQnpUjqC1RL0ltmf7w?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522dKddaQnpUjqC1RL0ltmf7w%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5ld2Nhc3RsZSUyMHVwb24lMjBUeW5lJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSnpXUnZESDZGZlVnUmtXR25jckJTNGdzJTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTU0Ljk3ODI1MiUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjYxNzc4JTdE&ps=1',
    },
    {
        id: 55,
        name: 'Grill N Chill', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ne',
        instagram: 'https://www.instagram.com/grillnchillashbrooke/?hl=en-gb',
        description: `They do what you fancy! 
        
        Serving up pizzas, kebabs and classic grill dishes to loyal customers in the North east. `,
        image: ['ne/5/1.jpg', 'ne/5/2.jpg', 'ne/5/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/grill-n-chill-ashbrook/xHfIa9HmWoqYC041fUZT3Q?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5ld2Nhc3RsZSUyMHVwb24lMjBUeW5lJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSnpXUnZESDZGZlVnUmtXR25jckJTNGdzJTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTU0Ljk3ODI1MiUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjYxNzc4JTdE',
        orderlink: 'https://www.ubereats.com/gb/store/grill-n-chill-ashbrook/xHfIa9HmWoqYC041fUZT3Q?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5ld2Nhc3RsZSUyMHVwb24lMjBUeW5lJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSnpXUnZESDZGZlVnUmtXR25jckJTNGdzJTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTU0Ljk3ODI1MiUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjYxNzc4JTdE',
    },
    {
        id: 56,
        name: 'Geisha Asia', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ne',
        instagram: 'https://www.instagram.com/geisharestaurants/?hl=en',
        description: `Geisha Asia is a modern, Japanese-inspired restaurant that offers an exquisite dining experience. 
        
        Our pan-asian menu features delicious sushi, dim-sum as well as a variety of other mouth-watering dishes.`,
        image: ['ne/6/1.jpg', 'ne/6/2.jpg', 'ne/6/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/geisha-asia-at-home/kNXKTsjHUDGUTtcG8Oif1g?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522kNXKTsjHUDGUTtcG8Oif1g%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5ld2Nhc3RsZSUyMHVwb24lMjBUeW5lJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSnpXUnZESDZGZlVnUmtXR25jckJTNGdzJTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTU0Ljk3ODI1MiUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjYxNzc4JTdE&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/geisha-asia-at-home/kNXKTsjHUDGUTtcG8Oif1g?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522kNXKTsjHUDGUTtcG8Oif1g%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5ld2Nhc3RsZSUyMHVwb24lMjBUeW5lJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSnpXUnZESDZGZlVnUmtXR25jckJTNGdzJTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTU0Ljk3ODI1MiUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjYxNzc4JTdE&ps=1',
    },
    {
        id: 57,
        name: 'SliceBurg', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ne',
        instagram: 'https://www.instagram.com/sliceburg/?hl=en',
        description: `Sliceburg are a local favourite in their hometown of Darlington, serving detroit style pizzas, loaded donner fries and top rated smash burgers!`,
        image: ['ne/7/1.jpg', 'ne/7/2.jpg', 'ne/7/3.jpg', 'ne/7/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/sliceburg/TbvmvqERXyOwb-XnspnczQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5ld2Nhc3RsZSUyMHVwb24lMjBUeW5lJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSnpXUnZESDZGZlVnUmtXR25jckJTNGdzJTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTU0Ljk3ODI1MiUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjYxNzc4JTdE',
        orderlink: 'https://www.ubereats.com/gb/store/sliceburg/TbvmvqERXyOwb-XnspnczQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5ld2Nhc3RsZSUyMHVwb24lMjBUeW5lJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSnpXUnZESDZGZlVnUmtXR25jckJTNGdzJTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTU0Ljk3ODI1MiUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjYxNzc4JTdE',
    },
    {
        id: 58,
        name: '40 Ounce', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ne',
        instagram: 'https://www.instagram.com/_40ounce_/?hl=en',
        description: `40 Ounce are a 90's Hip Hop Burger joint, bringing grass fed beef and dry cured meats to the streets of Gateshead and beyond. `,
        image: ['ne/8/1.jpg', 'ne/8/2.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/40-ounce/gmVHLZAdXKm6a6DW0I9HxA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5ld2Nhc3RsZSUyMHVwb24lMjBUeW5lJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSnpXUnZESDZGZlVnUmtXR25jckJTNGdzJTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTU0Ljk3ODI1MiUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjYxNzc4JTdE',
        orderlink: 'https://www.ubereats.com/gb/store/40-ounce/gmVHLZAdXKm6a6DW0I9HxA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5ld2Nhc3RsZSUyMHVwb24lMjBUeW5lJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSnpXUnZESDZGZlVnUmtXR25jckJTNGdzJTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTU0Ljk3ODI1MiUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjYxNzc4JTdE',
    },
    {
        id: 59,
        name: 'Little Lobo', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ne',
        instagram: 'https://www.instagram.com/littlelobotaqueria/?hl=en',
        description: `Independent informal Mexican restaurant & bar inspired by Californian dining and traditional Mexican street food. 
        
        Serving Soft Shell Tacos, Burritos and Margs up to Geordies all over!`,
        image: ['ne/9/1.jpg', 'ne/9/2.jpg', 'ne/9/3.jpg', 'ne/9/4.jpg', 'ne/9/5.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/little-lobo-sandyford/H5YoQ2ZeWY6IopFKjGH3vg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5ld2Nhc3RsZSUyMHVwb24lMjBUeW5lJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSnpXUnZESDZGZlVnUmtXR25jckJTNGdzJTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTU0Ljk3ODI1MiUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjYxNzc4JTdE',
        orderlink: 'https://www.ubereats.com/gb/store/little-lobo-sandyford/H5YoQ2ZeWY6IopFKjGH3vg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk5ld2Nhc3RsZSUyMHVwb24lMjBUeW5lJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSnpXUnZESDZGZlVnUmtXR25jckJTNGdzJTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTU0Ljk3ODI1MiUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjYxNzc4JTdE',
    },
    {
        id: 60,
        name: 'Pho 179', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ne',
        instagram: 'https://www.instagram.com/pho179.vn/',
        description: `Filling a gap in the market for food from this corner of South East Asia is Pho 179, named after the noodle soup which is a signature dish of the country.
        
        Delve into the fresh and fragrant dishes of Vietnam.`,
        image: ['ne/10/1.jpg', 'ne/10/2.jpg', 'ne/10/3.jpg', 'ne/10/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/pho-179/jq-hFij2XVqx6uivySh4lw?diningMode=DELIVERY',
        orderlink: 'https://www.ubereats.com/gb/store/pho-179/jq-hFij2XVqx6uivySh4lw?diningMode=DELIVERY',
    },
    {
        id: 61,
        name: 'Napoli Centro Pizzeria', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'y',
        instagram: 'https://www.instagram.com/napolicentropizza/',
        description: `We're a small restaurant (probably smaller than you're thinking!) focused on making the best dough possible and sourcing the best ingredients from incredible local producers, as well as our friends in Italy. 
        
        Our pizza is cooked the Neapolitan way, hot and fast, served alongside our Fritti menu (more than just arancini! We're talking Neapolitan street food classics). `,
        image: ['y/1/1.jpg', /* 'y/1/2.jpg',  */'y/1/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/napoli-centro-pizzeria/yHqlFudkXeODvNf02ASVww?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/napoli-centro-pizzeria/yHqlFudkXeODvNf02ASVww?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D&ps=1',
    },
    {
        id: 62,
        name: 'Unit', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'y',
        instagram: 'https://www.instagram.com/unit_sheffield/?hl=en',
        description: `Sheffield halal gourmet burger restaurant / Urban diner specialising in gourmet burgers, Philly cheese-steaks, freakshakes and much more. `,
        image: ['y/2/1.jpg', 'y/2/2.jpg', 'y/2/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/unit/3MyUD_WrT1OoSXERvsMkPw?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%25223MyUD_WrT1OoSXERvsMkPw%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/unit/3MyUD_WrT1OoSXERvsMkPw?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%25223MyUD_WrT1OoSXERvsMkPw%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D&ps=1',
    },
    {
        id: 63,
        name: 'Silvers Deli', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'y',
        instagram: 'https://www.instagram.com/silvers_deli/?hl=en',
        description: `Your Neighbourhood sandwich shop - serving up some of the best breakfast sandwiches and lunchtime new york style subs in Yorkshire, using only the most high quality locally sourced ingredients.`,
        image: ['y/3/1.jpg', 'y/3/2.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/silvers-deli/xxZ-fZERVbysNJzeq5Y6Mg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/silvers-deli/xxZ-fZERVbysNJzeq5Y6Mg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D',
    },
    {
        id: 64,
        name: 'Sqew Shawarma Bar', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'y',
        instagram: 'https://www.instagram.com/sqew.uk/?hl=en',
        description: `Nationally recognised local brand serving up the best quality shawarmas and other lebonese cuisine. 
        
        Using the freshest ingredients, there\'s nowhere else you can get authentic lebonese food like you can here. 
        
        Keep it Sqew.`,
        image: ['y/4/1.jpg', 'y/4/2.jpg', 'y/4/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/sqew-shawarma-bar/iZsTn0kbU7asMehvm50OGw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/sqew-shawarma-bar/iZsTn0kbU7asMehvm50OGw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D',
    },
    {
        id: 65,
        name: 'Mythos', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'y',
        instagram: 'https://www.instagram.com/mythosuk/',
        description: `A cherished name in Leeds' culinary landscape. 
        
        Originally established as a takeaway in the vibrant Hyde Park area, our journey has now expanded to bring you an even richer experience. 
        
        In 2023, we proudly opened MYTHOS Restaurant in Chapel Allerton, Leeds. 
        
        For consistent, high quality greek cuisine, there\'s nowhere that does it quite like Mythos.`,
        image: ['y/5/1.jpg', 'y/5/2.jpg', 'y/5/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/mythos-hyde-park/eADeHyy3RJKsILEixnYfxA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/mythos-hyde-park/eADeHyy3RJKsILEixnYfxA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D',
    },
    {
        id: 66,
        name: 'Sibu', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'y',
        instagram: 'https://www.instagram.com/sibuofficialuk/?hl=en',
        description: `Nestled in the vibrant town of Batley, Sibu offers a culinary journey like no other. 
        
        Our restaurant is dedicated to serving the finest Asian soul food, crafted with love, tradition, and a touch of modern flair. 
        
        At Sibu Batley, we pride ourselves on providing a diverse menu that is entirely Halal, ensuring that every dish meets the highest standards of quality and taste.`,
        image: ['y/6/1.jpg', 'y/6/2.jpg', 'y/6/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/sibu/3aycZTSxRGSPoShiMj-yMw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/sibu/3aycZTSxRGSPoShiMj-yMw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D',
    },
    {
        id: 67,
        name: 'My Peshawar Bradford', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'y',
        instagram: 'https://www.instagram.com/lovemypeshawar/',
        description: `Peshawar is capital city of KPK provience in Pakistan. 
        
        My Peshawar Bradford brings food from Northern Pakistan to England, Yorkshire (Bradford),  is known for its rich, bold flavors, with a heavy emphasis on meat dishes. 
        
        Popular foods include chapli kebabs, a spiced minced meat patty; Peshawari karahi, a tomato-based meat curry; and Kabuli pulao, a savory rice dish with meat, raisins, and nuts. 
        
        The cuisine reflects a blend of Central Asian, Persian, and South Asian influences, characterized by generous use of spices, herbs, and slow-cooking techniques. 
        
        Peshawar\'s food is hearty, flavorful, and deeply rooted in the region\'s cultural traditions`,
        image: ['y/7/1.jpg', 'y/7/2.jpg', 'y/7/3.jpg', 'y/7/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/my-peshawar-bradford/8bAmTdycUU6dnq83ercf5g?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/my-peshawar-bradford/8bAmTdycUU6dnq83ercf5g?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D',
    },
    {
        id: 68,
        name: 'MrTea', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'y',
        instagram: 'https://www.instagram.com/mrtea.leeds/?hl=en',
        description: `Right in the heart of Leeds, We mix up traditional recipes with the freshest ingredients to bring you flavors that are seriously crave-worthy. 
        
        Come for the Boba, stay for the Bento--it's all about good vibes and great tastes here!`,
        image: ['y/8/1.jpg', 'y/8/2.jpg', 'y/8/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/mr-tea/8h7oqM4tUMa6AJbhILmhUA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/mr-tea/8h7oqM4tUMa6AJbhILmhUA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D',
    },
    {
        id: 69,
        name: 'Little Snack Bar', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'y',
        instagram: 'https://www.instagram.com/littlesnackbaruk/',
        description: `We are the heart of authentic Taiwanese cuisine in Sheffield. 
        
        As the city\'s first Taiwanese restaurant, we bring the rich flavors and vibrant street food culture of Taiwan to your table. 
        
        Our cozy eatery offers a delicious selection of traditional dishes, from savory beef noodle soup to mouthwatering braised pork rice, all made with fresh ingreditents `,
        image: ['y/9/1.jpg', 'y/9/2.jpg', 'y/9/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/little-snack-bar-leeds-%E5%B0%8F%E9%A3%9F%E5%A0%82/vzse_6w2VHGNzlAdT_c8mw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/little-snack-bar-leeds-%E5%B0%8F%E9%A3%9F%E5%A0%82/vzse_6w2VHGNzlAdT_c8mw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D',
    },
    {
        id: 70,
        name: 'The Street Food Chef', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'y',
        instagram: 'https://www.instagram.com/streetfoodchefsheffield/',
        description: `We're a family run Mexican street food outlet passionate about providing a healthy fast food alternative to the people of Sheffield. 
        
        We're probably best known for our burritos but we also serve tacos and quesadillas as well as gluten and dairy free options. 
        
        We believe that fast food can taste great, without any of the added nasties - all our food is freshly prepared every day and we use locally sourced, fresh ingredients wherever possible.`,
        image: ['y/10/1.jpg', 'y/10/2.jpg', 'y/10/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/the-street-food-chef-the-canteen/QsFFz9TDXgKhy5s2dddN9A?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/the-street-food-chef-the-canteen/QsFFz9TDXgKhy5s2dddN9A?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMlNoZWZmaWVsZCUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpGYjdvLXFrS2VVZ1JlTEFHcl9VbktENCUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0E1My4zODExMjg5OTk5OTk5OSUyQyUyMmxvbmdpdHVkZSUyMiUzQS0xLjQ3MDA4NSU3RA%3D%3D',
    },
    {
        id: 71,
        name: 'Home Chinese Manchester', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'nw',
        instagram: 'https://www.instagram.com/homechinesemanchester/?hl=en',
        description: `"Home" is a Chinese restaurant that offers an authentic culinary experience, specializing in Cantonese and Sichuan cuisine. 
        
        With a dedicated in house Dim Sum kitchen, it delivers traditional flavors in a contemporary setting, combining the rich heritage of Chinese cuisine with modern dining aesthetics. 
        
        Whether you\'re craving the bold, spicy notes of Sichuan dishes or the delicate, savory flavors of Cantonese cuisine, "Home" provides a warm, inviting atmosphere to enjoy a truly authentic meal.`,
        image: ['nw/1/1.jpg', 'nw/1/2.jpg', 'nw/1/3.jpg', 'nw/1/4.jpg', 'nw/1/5.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/home-chinese-manchester/E4dmN7U9WGyUdA_lWPNm1A?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522E4dmN7U9WGyUdA_lWPNm1A%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/home-chinese-manchester/E4dmN7U9WGyUdA_lWPNm1A?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522E4dmN7U9WGyUdA_lWPNm1A%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D&ps=1',
    },
    {
        id: 72,
        name: 'Laros - Greek Street Food', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'nw',
        instagram: 'https://www.instagram.com/laros_greek/',
        description: `The New Home of Greek Street Food in Liverpool & Manchester!? 
        
        Laros don't compromise on quality or taste to ensure that you get the true Greek experience.`,
        image: ['nw/2/1.jpg', 'nw/2/2.jpg', 'nw/2/3.jpg', 'nw/2/4.jpg', 'nw/2/5.jpg', 'nw/2/6.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/laros-greek-street-food/N0rr5WDWSTi_6dVYN2Z0ug?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/laros-greek-street-food/N0rr5WDWSTi_6dVYN2Z0ug?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
    },
    {
        id: 73,
        name: 'SHAKEDOWN', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'nw',
        instagram: 'https://www.instagram.com/heyshakedown/?hl=en',
        description: `Home of the Beefed Up! Fresh burgers, crispy fries, fluffy waffles, creamy shakes, and don\'t miss their limited editions. 
        
        Something for everyone! `,
        image: ['nw/3/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/shakedown-withington/EEitYIJKSSa3p9NimUM0yw?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522EEitYIJKSSa3p9NimUM0yw%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/shakedown-withington/EEitYIJKSSa3p9NimUM0yw?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522EEitYIJKSSa3p9NimUM0yw%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D&ps=1',
    },
    {
        id: 74,
        name: 'the Nashville Cluck', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'nw',
        instagram: 'https://www.instagram.com/thenashvillecluck/?hl=en',
        description: `Hungry for some Cluck. 
        
        Since launching, Nashville\'s insane Buttermilk Crispy Chicken has become an irresistable staple on the Liverpool food scene. `,
        image: ['nw/4/1.jpg', 'nw/4/2.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/the-nashville-cluck/KE8nj97_WjWzPjSvGu7EiA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/the-nashville-cluck/KE8nj97_WjWzPjSvGu7EiA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
    },
    {
        id: 75,
        name: 'Zezu', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'nw',
        instagram: 'https://www.instagram.com/zezuliverpool/?hl=en',
        description: `Zezu invites you to embark on a culinary adventure through Asia.`,
        image: ['nw/5/1.jpg', 'nw/5/2.jpg', 'nw/5/3.jpg', 'nw/5/4.jpg', 'nw/5/5.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/zezu/HCbgr39mTiSVSgGZKL8Bbw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/zezu/HCbgr39mTiSVSgGZKL8Bbw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
    },
    {
        id: 76,
        name: 'Mamas Soul Food', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'nw',
        instagram: 'https://www.instagram.com/mamasoulfoodmcr/?hl=en',
        description: `Founded in 2021, Mama's Soul Food is a family-run business specialising in New Orleans style seafood boil, gourmet fried chicken and homemade Mac n Cheese. 
        
        The brand takes pride in premium seafood quality and the legendary Mama's sauce. 
        
        Let Mama feed your soul!`,
        image: ['nw/6/1.jpg', 'nw/6/2.jpg', 'nw/6/3.jpg', 'nw/6/4.jpg', 'nw/6/5.jpg', 'nw/6/6.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/mamas-soul-food/2ndTT8FYScS6vpe9OKBCtg?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%25222ndTT8FYScS6vpe9OKBCtg%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/mamas-soul-food/2ndTT8FYScS6vpe9OKBCtg?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%25222ndTT8FYScS6vpe9OKBCtg%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D&ps=1',
    },
    {
        id: 77,
        name: 'Aros Kitchen', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'nw',
        instagram: 'https://www.instagram.com/aros_kitchen/?hl=en',
        description: `Aros Kitchen is a culinary sanctuary where tradition meets innovation. 
        
        Here, the culinary traditions of the past are honored with a modern twist, making every dish a meal with a story behind it.`,
        image: ['nw/7/1.jpg', 'nw/7/2.jpg', 'nw/7/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/aros-kitchen-newton/2m1pcX7kUsutfpgdt9p7qg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/aros-kitchen-newton/2m1pcX7kUsutfpgdt9p7qg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
    },
    {
        id: 78,
        name: 'Burger & Co', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'nw',
        instagram: 'https://www.instagram.com/burgerandco.mcr_/',
        description: `Burger & Co offers the best quality burgers in Manchester. 
        
        We carefully choose the freshest ingredients and the best quality meat for our burgers! 
        
        We have vegan options too For everyone with a sweet tooth you won't be disappointed in our shakes or desserts either`,
        image: ['nw/8/1.png', 'nw/8/2.jpeg', 'nw/8/3.png', 'nw/8/4.png', 'nw/8/5.png', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/burger-%26-co/awjQf944T4ypyGBDZelnUA?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522awjQf944T4ypyGBDZelnUA%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/burger-%26-co/awjQf944T4ypyGBDZelnUA?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522awjQf944T4ypyGBDZelnUA%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D&ps=1',
    },
    {
        id: 79,
        name: 'Istanbul Restaurant', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'nw',
        instagram: 'https://www.instagram.com/istanbulrestaurantmanchester/?hl=en',
        description: `At Istanbul Restaurant they take pride in serving original authentic Turkish food, using their own unique blend of fresh ingredients. 
        
        Their experienced Turkish chefs have conquered the art and essence of Turkish cuisine with every plate cooked with love and devotion.`,
        image: ['nw/9/1.jpg', 'nw/9/2.jpg', 'nw/9/3.jpg', 'nw/9/4.jpg', 'nw/9/5.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/store/istanbul-restaurant-cheetham-hill/aHsHyh-xWBy8BaYcWCa18A?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522aHsHyh-xWBy8BaYcWCa18A%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D&ps=1&utm_source=wok',
        orderlink: 'https://www.ubereats.com/store/istanbul-restaurant-cheetham-hill/aHsHyh-xWBy8BaYcWCa18A?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522aHsHyh-xWBy8BaYcWCa18A%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D&ps=1&utm_source=wok',
    },
    {
        id: 80,
        name: 'Go Burrito', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'nw',
        instagram: 'https://www.instagram.com/goburrito_uk/',
        description: `The home of Slow Cooled Fast Food! We've perfected Mexican inspired burritos, loaded nachos, fried, chillies and more, sourcing our ingredients from local suppliers and working with local businesses to give back to our communities.`,
        image: ['nw/10/1.jpg', 'nw/10/2.jpg', 'nw/10/3.jpg', 'nw/10/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/store/go-burrito-central/BbdsOJo9W-6_HULxyDB-KA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D&utm_source=wok',
        orderlink: 'https://www.ubereats.com/store/go-burrito-central/BbdsOJo9W-6_HULxyDB-KA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D&utm_source=wok',
    },
    {
        id: 81,
        name: '3 Levels', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ni',
        instagram: 'https://www.instagram.com/3levelsbelfast/',
        description: `3 Levels Asian Fusion offers you the magnificent Japanese Teppanyaki live cooking experience, delicious sushi and yummy classic Chinese food`,
        image: ['ni/1/1.jpg', 'ni/1/2.jpg', 'ni/1/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/3-levels-japanese-teppanyaki/MDZPIwKbR_WTOEaed9X1GA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/3-levels-japanese-teppanyaki/MDZPIwKbR_WTOEaed9X1GA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMk1hbmNoZXN0ZXIlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKMl9VbVVreE5la2dScW12LUJEZ1V2dGslMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTMuNDgwNzU5MyUyQyUyMmxvbmdpdHVkZSUyMiUzQS0yLjI0MjYzMDUlN0Q%3D',
    },
    {
        id: 82,
        name: 'Pizza Crew', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ni',
        instagram: 'https://www.instagram.com/__pizzacrew/',
        description: `We are "PIZZACREW." We are a vibrant family run pizza spot who are top rated in Ireland. 
        
        We are passionate about delivering the ultimate pizza experience.`,
        image: ['ni/2/1.jpg', 'ni/2/2.jpg', 'ni/2/3.jpg', 'ni/2/4.jpg', 'ni/2/5.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/pizza-crew-andersonstown/3uLtzOQlX5mu0ZutW05l4g?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/pizza-crew-andersonstown/3uLtzOQlX5mu0ZutW05l4g?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D&ps=1',
    },
    {
        id: 83,
        name: 'Juice Jar', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ni',
        instagram: 'https://www.instagram.com/thejuicejar/?hl=en',
        description: `Founded in September 2019, Juice Jar is Coffee Shop/Juice Bar hybrid matching experience with clean, fresh and health Juices, Smoothies, Berry Bowls, Melted Sandwiches and Specialty Coffee`,
        image: ['ni/3/1.jpg', 'ni/3/2.jpg', 'ni/3/3.jpg', 'ni/3/4.jpg', 'ni/3/5.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/the-juice-jar-botanic/ZccTGucZS6ON5rJ_FFKZDg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/the-juice-jar-botanic/ZccTGucZS6ON5rJ_FFKZDg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D',
    },
    {
        id: 84,
        name: 'Pizza Co', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ni',
        instagram: 'https://www.instagram.com/pizzaco_belfast/',
        description: `A business with a passion for pizza; at the heart of pizza cos success is a commitment to quality `,
        image: ['ni/4/1.jpg', 'ni/4/2.jpg', 'ni/4/3.jpg', 'ni/4/4.jpg', 'ni/4/5.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/the-pizza-co/a3cSopXVSsOOxSyp7SDEhA?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522a3cSopXVSsOOxSyp7SDEhA%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/the-pizza-co/a3cSopXVSsOOxSyp7SDEhA?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522a3cSopXVSsOOxSyp7SDEhA%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D&ps=1',
    },
    {
        id: 85,
        name: 'Eatao', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ni',
        instagram: '',
        description: `Committed to using only the freshest ingredients, we carefully create each dish to bring you the authentic taste of Asian cuisine.`,
        image: ['ni/5/1.jpg', 'ni/5/2.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/eatao-asian-fusion/Trq7ers7UGSWMFaddXJgWg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/eatao-asian-fusion/Trq7ers7UGSWMFaddXJgWg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D',
    },
    {
        id: 86,
        name: 'Nu Delhi', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ni',
        instagram: 'https://www.facebook.com/ndlounge/?locale=en_GB',
        description: `Nu Delhi Lounge, a high end 150 seater Indian restaurant welcomes you to a 'Nu' concept of eating out. 
        
        Food, cocktails and music, all in one place and all delivered to perfection.`,
        image: ['ni/6/1.jpg', 'ni/6/2.jpg', 'ni/6/3.jpg', 'ni/6/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/nu-delhi/etNesRIETVu9dlMzNAJt-Q?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522etNesRIETVu9dlMzNAJt-Q%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/nu-delhi/etNesRIETVu9dlMzNAJt-Q?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522etNesRIETVu9dlMzNAJt-Q%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D&ps=1',
    },
    {
        id: 87,
        name: 'Bao Bun', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ni',
        instagram: 'https://www.instagram.com/baobunstreetfood/?hl=en',
        description: `Based in Belfast and Dublin, we take immense pride in crafting the highest quality Bao Bun. 
        
        Our commitment to excellence is reflected in our meticulous and precise practices carried out by our skilled and dedicated Bao Artists`,
        image: ['ni/7/1.jpg', 'ni/7/2.jpg', 'ni/7/3.jpg', 'ni/7/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/baobun-botanic/tj7STCN1Wje05bYvzs6FVw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/baobun-botanic/tj7STCN1Wje05bYvzs6FVw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D',
    },
    {
        id: 88,
        name: 'Yo Burger', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ni',
        instagram: 'https://www.instagram.com/yoburgerbar/?hl=en-gb',
        description: `Home to the best fully loaded buns and dirty fries in town! 
        
        Opened in November 2023 in The Goats Toe bar on Main Street in Bangor, Yo Burger is the new kid on the block with its eyes well and truly on the fries!`,
        image: ['ni/8/1.jpg', 'ni/8/2.jpg', 'ni/8/3.jpg', 'ni/8/4.jpg', 'ni/8/5.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/yo-burger-belfast/5FVgyl_IXwGBO4-V_Rsctg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/yo-burger-belfast/5FVgyl_IXwGBO4-V_Rsctg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D',
    },
    {
        id: 89,
        name: 'Mackles', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ni',
        instagram: 'https://www.instagram.com/macklesicecream/?hl=en',
        description: `Customers best describe Mackles Ice Cream as the 'ultimate ice cream spot!' 
        
        Offering an endless list of toppings, sauces, ice cream options (including vegan and diary free) and milkshakes, there's something for everyone on the menu`,
        image: ['ni/9/1.jpg', 'ni/9/2.jpg', 'ni/9/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/mackles-ice-cream-belfast/GNIJaREzVveiXxp3Rtjapw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/mackles-ice-cream-belfast/GNIJaREzVveiXxp3Rtjapw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D',
    },
    {
        id: 90,
        name: 'Mama Bobo', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'ni',
        instagram: 'https://www.instagram.com/mamaboborestaurant/',
        description: `At Mama Bobo Restaurant we love African Food, and always do our best to show you some of the best of what African Food has to offer. 
        
        We always work with Fresh, high-quality ingredients so that your taste buds are truly pampered`,
        image: ['ni/10/1.jpg', 'ni/10/2.jpg', 'ni/10/3.jpg', 'ni/10/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/mama-bobo-restaurant/fBYs2Wk_XsKQX_xu53hhyw?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522fBYs2Wk_XsKQX_xu53hhyw%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/mama-bobo-restaurant/fBYs2Wk_XsKQX_xu53hhyw?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522fBYs2Wk_XsKQX_xu53hhyw%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D&ps=1',
    },
    {
        id: 91,
        name: 'Salt & Chilli Oriental', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 's',
        instagram: 'https://www.instagram.com/saltandchilligla/?hl=en',
        description: `Salt & Chilli Oriental in Glasgow serves up bold Hong Kong-style street food with a modern twist. 
        
        Known for its delicious salt and chilli dishes, the menu features crispy chicken, flavorful noodles, and a variety of dim sum. 
        
        Enjoy a vibrant, welcoming atmosphere and a true taste of Asian street food.`,
        image: ['s/1/1.jpg', 's/1/2.jpg', 's/1/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/salt-%26-chilli-oriental/wCs-7ge-Xk6QbYd9kp4MZQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/salt-%26-chilli-oriental/wCs-7ge-Xk6QbYd9kp4MZQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJlbGZhc3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKTzRyUTFfM19ZRWdSTWNYSDd5d1dWeTQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTQuNTk3Mjg1MDAwMDAwMDElMkMlMjJsb25naXR1ZGUlMjIlM0EtNS45MzAxMiU3RA%3D%3D',
    },
    {
        id: 92,
        name: 'TOFU Asian Fusion', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 's',
        instagram: 'https://www.instagram.com/tofuasianfusion/',
        description: `TOFU Asian Fusion offers a vibrant mix of vegan and vegetarian Pan-Asian cuisine. 
        
        Inspired by the street food culture of Asia, the restaurant features fresh, locally sourced ingredients in dishes ranging from ramen to sushi. 
        
        Perfect for plant-based food lovers and anyone seeking bold, creative flavors.`,
        image: ['s/2/1.jpg', 's/2/2.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/tofu-asian-fusion-%E8%B1%86%E7%A6%8F/EWvriikLSc21kl4kWP4qmw?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522EWvriikLSc21kl4kWP4qmw%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkdsYXNnb3clMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKNjg1V0lGWVZpRWdSSGxIdkJiaUQ1bkUlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTUuODYxNjcwMzk5OTk5OTklMkMlMjJsb25naXR1ZGUlMjIlM0EtNC4yNTgzMzQ1JTdE&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/tofu-asian-fusion-%E8%B1%86%E7%A6%8F/EWvriikLSc21kl4kWP4qmw?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522EWvriikLSc21kl4kWP4qmw%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkdsYXNnb3clMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKNjg1V0lGWVZpRWdSSGxIdkJiaUQ1bkUlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTUuODYxNjcwMzk5OTk5OTklMkMlMjJsb25naXR1ZGUlMjIlM0EtNC4yNTgzMzQ1JTdE&ps=1',
    },
    {
        id: 93,
        name: 'Chilo\'s Burgers', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 's',
        instagram: 'https://www.instagram.com/chilosburgers/?hl=en',
        description: `Happiness in every bite. At Chilo's®, food is our DNA with the goal of serving a family-style menu of tasty, bold-flavoured food. We make sure everything we produce is of the highest standard and best quality. 
        
        Each item on the menu is made to order, right in front of our customers in our open kitchen. 
        
        Our desire to serve mouth-watering perfection and never cut corners which will quickly set us apart from the crowd.`,
        image: ['s/3/1.jpg', 's/3/2.jpg', 's/3/3.jpg', 's/3/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/chilos-burgers-uddingston/nFmOhtqvWkCjvvK3aQbpfQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkdsYXNnb3clMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKNjg1V0lGWVZpRWdSSGxIdkJiaUQ1bkUlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTUuODYxNjcwMzk5OTk5OTklMkMlMjJsb25naXR1ZGUlMjIlM0EtNC4yNTgzMzQ1JTdE',
        orderlink: 'https://www.ubereats.com/gb/store/chilos-burgers-uddingston/nFmOhtqvWkCjvvK3aQbpfQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkdsYXNnb3clMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKNjg1V0lGWVZpRWdSSGxIdkJiaUQ1bkUlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTUuODYxNjcwMzk5OTk5OTklMkMlMjJsb25naXR1ZGUlMjIlM0EtNC4yNTgzMzQ1JTdE',
    },
    {
        id: 94,
        name: 'Figo\'s', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 's',
        instagram: 'https://www.instagram.com/figosperiperi/?hl=en',
        description: `Delicious & Authentic PERI PERI CHICKEN. We've crafted the perfect recipe to make sure we live up to Our by-line

All of our stores serve meat which is 100% Halal, sourced from the best suppliers who live up to our strict standards and quality measures. 

We pride ourselves on serving food which the whole family can enjoy and therefore offer a diverse menu catering for both adults and children. 

Our menu is full of nutritious dishes which includes everything from chicken pieces, chicken burgers, strips, wings.`,
        image: ['s/4/1.jpg', 's/4/2.jpg', 's/4/3.jpg', 's/4/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/figos-peri-peri-southside/wW23FMJsTROkCnofUBZ1BQ?pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkdsYXNnb3clMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKNjg1V0lGWVZpRWdSSGxIdkJiaUQ1bkUlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTUuODYxNjcwMzk5OTk5OTklMkMlMjJsb25naXR1ZGUlMjIlM0EtNC4yNTgzMzQ1JTdE',
        orderlink: 'https://www.ubereats.com/gb/store/figos-peri-peri-southside/wW23FMJsTROkCnofUBZ1BQ?pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkdsYXNnb3clMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKNjg1V0lGWVZpRWdSSGxIdkJiaUQ1bkUlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTUuODYxNjcwMzk5OTk5OTklMkMlMjJsb25naXR1ZGUlMjIlM0EtNC4yNTgzMzQ1JTdE',
    },
    {
        id: 95,
        name: 'Damasqino', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 's',
        instagram: 'https://www.instagram.com/damasqino_restaurant/?hl=en',
        description: `Voted "Middle Eastern Restaurant of the Year" at the 2024 Scottish Restaurant Awards, Damasqino is a one-stop place to satiate your love for Lebanese food.`,
        image: ['s/5/1.jpg', 's/5/2.jpg', 's/5/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/store/damasqino/B07_j4VjSYSSwGniJGB4jQ?ad_id=534104609719&campaign_id=11866995810&diningMode=DELIVERY&gclid=CjwKCAjw5qC2BhB8EiwAvqa41hO3Y39fG1yF23Nn3FD8_iL8c3txmpWBf-C5OGEnYVJGKWA3cEs-lxoC7pUQAvD_BwE&gclsrc=aw.ds&kw=damasqino&kwid=kwd-677032965261&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkdsYXNnb3clMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKNjg1V0lGWVZpRWdSSGxIdkJiaUQ1bkUlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTUuODYxNjcwMzk5OTk5OTklMkMlMjJsb25naXR1ZGUlMjIlM0EtNC4yNTgzMzQ1JTdE&placement=&utm_campaign=CM2055577-search-google-nonbrand_184_-99_GB-National_e_web_acq_cpc_en_1_Restaurants_Exact_damasqino_kwd-677032965261_534104609719_123532642223_e_c&utm_source=AdWords_NonBrand',
        orderlink: 'https://www.ubereats.com/store/damasqino/B07_j4VjSYSSwGniJGB4jQ?ad_id=534104609719&campaign_id=11866995810&diningMode=DELIVERY&gclid=CjwKCAjw5qC2BhB8EiwAvqa41hO3Y39fG1yF23Nn3FD8_iL8c3txmpWBf-C5OGEnYVJGKWA3cEs-lxoC7pUQAvD_BwE&gclsrc=aw.ds&kw=damasqino&kwid=kwd-677032965261&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkdsYXNnb3clMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKNjg1V0lGWVZpRWdSSGxIdkJiaUQ1bkUlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTUuODYxNjcwMzk5OTk5OTklMkMlMjJsb25naXR1ZGUlMjIlM0EtNC4yNTgzMzQ1JTdE&placement=&utm_campaign=CM2055577-search-google-nonbrand_184_-99_GB-National_e_web_acq_cpc_en_1_Restaurants_Exact_damasqino_kwd-677032965261_534104609719_123532642223_e_c&utm_source=AdWords_NonBrand',
    },
    {
        id: 96,
        name: 'Yippon', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 's',
        instagram: 'https://www.instagram.com/yipponuk/?hl=en',
        description: `Yippon is founded on the concept of convenient quality Asian food. 
        
        Priding themselves on their ancestry, convenience doesn\'t mean settlings for anything less than the best. `,
        image: ['s/6/1.jpg', 's/6/2.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/yippon/3AKfV4BSSrSP4Gmo7S0CSA?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%25223AKfV4BSSrSP4Gmo7S0CSA%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkdsYXNnb3clMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKNjg1V0lGWVZpRWdSSGxIdkJiaUQ1bkUlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTUuODYxNjcwMzk5OTk5OTklMkMlMjJsb25naXR1ZGUlMjIlM0EtNC4yNTgzMzQ1JTdE&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/yippon/3AKfV4BSSrSP4Gmo7S0CSA?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%25223AKfV4BSSrSP4Gmo7S0CSA%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkdsYXNnb3clMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKNjg1V0lGWVZpRWdSSGxIdkJiaUQ1bkUlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTUuODYxNjcwMzk5OTk5OTklMkMlMjJsb25naXR1ZGUlMjIlM0EtNC4yNTgzMzQ1JTdE&ps=1',
    },
    {
        id: 97,
        name: 'Razzo', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 's',
        instagram: 'https://www.instagram.com/razzopizzanapoletana/?hl=en-gb',
        description: `At Razzo we take pride in offering authentic neapolitan pizza made with love`,
        image: ['s/7/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/razzo-pizza-napoletana/cKnW1-3KUQKdjYzg7uYcBw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkdsYXNnb3clMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKNjg1V0lGWVZpRWdSSGxIdkJiaUQ1bkUlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTUuODYxNjcwMzk5OTk5OTklMkMlMjJsb25naXR1ZGUlMjIlM0EtNC4yNTgzMzQ1JTdE',
        orderlink: 'https://www.ubereats.com/gb/store/razzo-pizza-napoletana/cKnW1-3KUQKdjYzg7uYcBw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkdsYXNnb3clMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKNjg1V0lGWVZpRWdSSGxIdkJiaUQ1bkUlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTUuODYxNjcwMzk5OTk5OTklMkMlMjJsb25naXR1ZGUlMjIlM0EtNC4yNTgzMzQ1JTdE',
    },
    {
        id: 98,
        name: 'El Perro', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 's',
        instagram: 'https://www.instagram.com/elperronegroburgers/?hl=en',
        description: `Two-time winners of UK\'s National Burger Awards, El Perro Negro know that great burgers start with great beef and outstanding quality.`,
        image: ['s/8/1.jpg', 's/8/2.jpg', 's/8/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/el-perro-negro/orcZ8YFeSrezsi6j1o1LAA?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522orcZ8YFeSrezsi6j1o1LAA%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkdsYXNnb3clMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKNjg1V0lGWVZpRWdSSGxIdkJiaUQ1bkUlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTUuODYxNjcwMzk5OTk5OTklMkMlMjJsb25naXR1ZGUlMjIlM0EtNC4yNTgzMzQ1JTdE&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/el-perro-negro/orcZ8YFeSrezsi6j1o1LAA?diningMode=DELIVERY&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522orcZ8YFeSrezsi6j1o1LAA%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkdsYXNnb3clMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKNjg1V0lGWVZpRWdSSGxIdkJiaUQ1bkUlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTUuODYxNjcwMzk5OTk5OTklMkMlMjJsb25naXR1ZGUlMjIlM0EtNC4yNTgzMzQ1JTdE&ps=1',
    },
    {
        id: 99,
        name: 'Bibimbap', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 's',
        instagram: 'https://www.instagram.com/bibimbapgla/?locale=bz-hans&hl=am-et',
        description: `Home to the best Korean food in Scotland\'s Central Belt, Bibimbap provides fresh, authentic and wholesome Korean food.`,
        image: ['s/9/1.jpg', 's/9/2.jpg', 's/9/3.jpg', 's/9/4.jpg', 's/9/5.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/halloumi/eNjEtHJQQ1mUZtVWhrg0EQ?ad_id=699888196297&campaign_id=19866728626&chain_slug=bibimbap&gclid=CjwKCAjw5qC2BhB8EiwAvqa41iey9wNV3ZV-FariK6aHiSHNPO6jLj5tNHTTHEowZjuhsgMjemoq9xoCWDcQAvD_BwE&gclsrc=aw.ds&kw=bibimbap%20glasgow&kwid=kwd-531335535501&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522eNjEtHJQQ1mUZtVWhrg0EQ%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkdsYXNnb3clMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKNjg1V0lGWVZpRWdSSGxIdkJiaUQ1bkUlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTUuODYxNjcwMzk5OTk5OTklMkMlMjJsb25naXR1ZGUlMjIlM0EtNC4yNTgzMzQ1JTdE&placement=&ps=1&utm_campaign=CM2237851-search-google-nonbrand_184_-99_GB-National_e_web_acq_cpc_en_Enterprise_Exact_bibimbap%20glasgow_kwd-531335535501_699888196297_162657947436_e_c&utm_source=AdWords_NonBrand',
        orderlink: 'https://www.ubereats.com/gb/store/halloumi/eNjEtHJQQ1mUZtVWhrg0EQ?ad_id=699888196297&campaign_id=19866728626&chain_slug=bibimbap&gclid=CjwKCAjw5qC2BhB8EiwAvqa41iey9wNV3ZV-FariK6aHiSHNPO6jLj5tNHTTHEowZjuhsgMjemoq9xoCWDcQAvD_BwE&gclsrc=aw.ds&kw=bibimbap%20glasgow&kwid=kwd-531335535501&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522eNjEtHJQQ1mUZtVWhrg0EQ%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkdsYXNnb3clMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKNjg1V0lGWVZpRWdSSGxIdkJiaUQ1bkUlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTUuODYxNjcwMzk5OTk5OTklMkMlMjJsb25naXR1ZGUlMjIlM0EtNC4yNTgzMzQ1JTdE&placement=&ps=1&utm_campaign=CM2237851-search-google-nonbrand_184_-99_GB-National_e_web_acq_cpc_en_Enterprise_Exact_bibimbap%20glasgow_kwd-531335535501_699888196297_162657947436_e_c&utm_source=AdWords_NonBrand',
    },
    {
        id: 100,
        name: 'Mana Poke', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 's',
        instagram: 'https://www.instagram.com/manapokebowls/?hl=en-gb',
        description: `Mana Poké provide feel good food in the form of the freshest and best quality poké bowls around`,
        image: ['s/10/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: '',
        orderlink: '',
    },
    {
        id: 101,
        name: 'Half Man! Half Burger!', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'se',
        instagram: 'https://www.instagram.com/halfmanhalfburger/?hl=en',
        description: `Hell-bent on creating the freshest locally-sourced fresh beef & vegan burgers on planet earth.`,
        image: ['se/1/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/half-man!-half-burger!/oDirxtPTXHCmORnE51vLxg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/half-man!-half-burger!/oDirxtPTXHCmORnE51vLxg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE&ps=1',
    },
    {
        id: 102,
        name: 'Social Board', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'se',
        instagram: 'https://www.instagram.com/socialboardsandwiches/',
        description: `From freshly made sandwiches to hearty breakfasts and loaded fries, they've got you covered for any occasion. 
        
        Whether you're treating yourself to a takeaway at work, enjoying a beach day with friends, or a cosy meal at home, there are plenty of options for everyone.`,
        image: ['se/2/1.jpg', 'se/2/2.jpg', 'se/2/3.jpg', 'se/2/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/social-board/EOcfepu1Rzayg234Pv_t5w?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE',
        orderlink: 'https://www.ubereats.com/gb/store/social-board/EOcfepu1Rzayg234Pv_t5w?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE',
    },
    {
        id: 103,
        name: 'Carlito Burrito', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'se',
        instagram: 'https://www.instagram.com/carlitoburritobrighton/?hl=en-gb',
        description: `Carlito Burrito does great burritos, but that's not all they do. 
        
        If you are happy to settle in for a bit and spend a little more money, the Mexican street food dishes to share are a fantastic way to sink your teeth into much more of this glorious cuisine. 
        
        With everything from steak tacos, tostadas, quesadillas and ceviche to choose from you are going to need more than one visit to fully experience the menu.`,
        image: ['se/3/1.jpg', 'se/3/2.jpg', 'se/3/3.jpg', 'se/3/4.jpg', 'se/3/5.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/carlito-burrito/tgHD1FBKSRS4d833ix3Kvg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE',
        orderlink: 'https://www.ubereats.com/gb/store/carlito-burrito/tgHD1FBKSRS4d833ix3Kvg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE',
    },
    {
        id: 104,
        name: 'Cutie Pies & Fries- Detroit Pizza', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'se',
        instagram: 'https://www.instagram.com/cutiepiesandfries/?hl=en',
        description: `Cutie Pies breaks the rules of traditional pizza experiences. 
        
        Our pizzas are rectangular, not round. They are crunchy, not chewy and fluffy, not flat. 
        
        We are the deep dish dream that we've all been missing in our culinary lives and cheese pulls are what we do best. 
        
        We managed to convince our good friends at The Star & Garter Pub, Brighton to let us sling square pizzas through the hatch to their customers. 
        
        That was over 6 months ago and the rest is now history.`,
        image: ['se/4/1.jpg', 'se/4/2.jpg', 'se/4/3.jpg', 'se/4/4.jpg', 'se/4/5.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/cutie-pies-%26-fries-detroit-pizza/k-3uqr7sWv-Ky-RXOBk_uw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE',
        orderlink: 'https://www.ubereats.com/gb/store/cutie-pies-%26-fries-detroit-pizza/k-3uqr7sWv-Ky-RXOBk_uw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE',
    },
    {
        id: 105,
        name: 'Natty\'s Jerk', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'se',
        isWinner: true,
        instagram: 'https://www.instagram.com/nattysjerk/',
        description: `We are Natty's Jerk, a community-led Jamaican food experience feeding hungry bellies along the South Coast, UK. 
        
        We call it a food experience because it's about SO much more than the food on your plate. 
        
        We're here to share the tastes, stories and culture of the Caribbean with you, your dad and anyone else we can get our hands on.`,
        image: ['se/5/1.jpg', 'se/5/2.jpg', 'se/5/3.jpg', 'se/5/4.jpg', 'se/5/5.jpg', ],
        winImage: '2024-winners/roty-winner-2024_final.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/nattys-jerk-shack/wfwaS8mQTnKGRGcaoPLulg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE',
        orderlink: 'https://www.ubereats.com/gb/store/nattys-jerk-shack/wfwaS8mQTnKGRGcaoPLulg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE',
    },
    {
        id: 106,
        name: 'Fuego\'s Street Food', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'se',
        instagram: 'https://www.instagram.com/fuegos.street/?hl=en',
        description: `Tacos anyone?? Gourmet hand-crafted burgers and wraps! 
        
        Pulled Jackfruit burger and Portsmouth's very first beyond-meat patty. 
        
        Grilled chicken and lamb chops. 
        
        Offering Vegan options with our 'make me vegan' range.`,
        image: ['se/6/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/fuegos-street/wYd4f1Z0UtGP9QcAPoyIEA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE',
        orderlink: 'https://www.ubereats.com/gb/store/fuegos-street/wYd4f1Z0UtGP9QcAPoyIEA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE',
    },
    {
        id: 107,
        name: 'Mexifun', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'se',
        instagram: 'https://www.instagram.com/mexifunstreetfood/',
        description: `At Mexifun, we are passionate about bringing the authentic flavors and culture of Mexico to our customers. 
        
        Founded with the vision of providing a dining experience that is both delicious and fun, we strive to create a warm and inviting atmosphere that welcomes everyone. 
        
        Our menu is crafted with care, using only the freshest and finest ingredients to ensure that every bite is bursting with flavor.`,
        image: ['se/7/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/mexifun/PCLN7HCDS7yEg0g0e4m93A?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE',
        orderlink: 'https://www.ubereats.com/gb/store/mexifun/PCLN7HCDS7yEg0g0e4m93A?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE',
    },
    {
        id: 108,
        name: 'Mango Thai', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'se',
        instagram: 'https://www.instagram.com/mangothai.abovebar/',
        description: `Mango Thai Tapas is known for its Thai tapas and curries, which are made with fresh herbs from Thailand and local vegetables and meats. 
        
        The menu also includes traditional Thai dishes, as well as unique creations from the Thai chefs who combine Western and Thai flavors. `,
        image: ['se/8/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/mango-thai-tapas-portswood/by_XCd35XO2MGnyHK556cQ?pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE',
        orderlink: 'https://www.ubereats.com/gb/store/mango-thai-tapas-portswood/by_XCd35XO2MGnyHK556cQ?pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE',
    },
    {
        id: 109,
        name: 'Baffi', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'se',
        instagram: 'https://www.instagram.com/baffipizzeria/?hl=en-gb',
        description: `We believe in getting it right. Simple pleasures in life are the most important. 
        
        Family, authentic Italian food and enjoying time with your loved ones. 
        
        The toppings we use are simple and fresh. 
        
        There are no hidden ingredients. 
        
        We don't need to. 
        
        Simple and authentic wins every time. `,
        image: ['se/9/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/baffi-pizzeria-southbourne/fiG49TXITCaEYzse1x7D_g?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE',
        orderlink: 'https://www.ubereats.com/gb/store/baffi-pizzeria-southbourne/fiG49TXITCaEYzse1x7D_g?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE',
    },
    {
        id: 110,
        name: 'The Boring Burger', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'se',
        instagram: 'https://www.instagram.com/theboringburger/?hl=en',
        description: `Chef Jamie\'s passion for food and his experience working in some of the UK\'s best restaurants lead him directly to launching his viral fine dining burger concept with a real street food twist nestled right in the centre of Guildford. 
        
        The finest ingredients, handmade. Everything fresh. `,
        image: ['se/10/1.jpg', 'se/10/2.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/the-boring-burger/q8eefujJWw6IQymgfqOobA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE',
        orderlink: 'https://www.ubereats.com/gb/store/the-boring-burger/q8eefujJWw6IQymgfqOobA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaWdodG9uJTIyJTJDJTIycmVmZXJlbmNlJTIyJTNBJTIyQ2hJSlowRXA5Z21GZFVnUi1RNTljbnF2eHB3JTIyJTJDJTIycmVmZXJlbmNlVHlwZSUyMiUzQSUyMmdvb2dsZV9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjgyMjk0MDIlMkMlMjJsb25naXR1ZGUlMjIlM0EtMC4xMzYyNjcyJTdE',
    },
    {
        id: 111,
        name: 'Low & Slow', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'sw',
        instagram: 'https://www.instagram.com/lowandslowbarbecue/?hl=en',
        description: `Moving from pop ups, to markets, to a physical store - Low & Slow has quickly become one of Bristol\'s favourite indie food spots! 
        
        They smoke all of their meats overnight to bring you the freshest barbecue in Bristol, straight off the smoker! 
        
        Always made in house and never reheated!`,
        image: ['sw/1/1.jpg', 'sw/1/2.jpg', 'sw/1/3.jpg', 'sw/1/4.jpg', 'sw/1/5.jpg', 'sw/1/6.jpg', 'sw/1/7.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/low-and-slow-barbecue-whiteladies/Yxb2FTi3RAqz0Vj-dc-_wQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/low-and-slow-barbecue-whiteladies/Yxb2FTi3RAqz0Vj-dc-_wQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D&ps=1',
    },
    {
        id: 112,
        name: 'Urban Tandoor', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'sw',
        instagram: 'https://www.instagram.com/urbantandoor/?hl=en',
        description: `Urban Tandoor, the pioneers of delicious, traditional Indian cuisine in Bristol. 
        
        They offer an enjoyable, memorable dining and delivery experience. 
        
        Part time chefs, full time local celebrities - Urban Tandoor have reached worldwide audiences with their innovative takes on trends on their social media platforms.`,
        image: ['sw/2/1.jpg', 'sw/2/2.jpg', 'sw/2/3.jpg', 'sw/2/4.jpg', 'sw/2/5.jpg', 'sw/2/6.jpg'/* , 'sw/2/7.jpg' */, 'sw/2/8.jpg', 'sw/2/9.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/urban-tandoor/BSuaAZdmX9O7XVQ2i3jsVQ?ad_id=705436950926&campaign_id=19867211659&diningMode=DELIVERY&gclid=CjwKCAjw5qC2BhB8EiwAvqa41rQBU_fanTUYLWS9YADOv4vtPVJEf-gbUwzLSVpIe7etifkS2544OxoCWZ0QAvD_BwE&gclsrc=aw.ds&kw=&kwid=dsa-2185644236092&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522BSuaAZdmX9O7XVQ2i3jsVQ%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D&placement=&ps=1&utm_campaign=CM2256489-search-google-nonbrand_184_-99_GB-National_e_all_acq_cpc_en_SMB_DSA_Exact__dsa-2185644236092_705436950926_146510978079__c&utm_source=AdWords_NonBrand',
        orderlink: 'https://www.ubereats.com/gb/store/urban-tandoor/BSuaAZdmX9O7XVQ2i3jsVQ?ad_id=705436950926&campaign_id=19867211659&diningMode=DELIVERY&gclid=CjwKCAjw5qC2BhB8EiwAvqa41rQBU_fanTUYLWS9YADOv4vtPVJEf-gbUwzLSVpIe7etifkS2544OxoCWZ0QAvD_BwE&gclsrc=aw.ds&kw=&kwid=dsa-2185644236092&mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522BSuaAZdmX9O7XVQ2i3jsVQ%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D&placement=&ps=1&utm_campaign=CM2256489-search-google-nonbrand_184_-99_GB-National_e_all_acq_cpc_en_SMB_DSA_Exact__dsa-2185644236092_705436950926_146510978079__c&utm_source=AdWords_NonBrand',
    },
    {
        id: 113,
        name: 'Lj Hugs', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'sw',
        instagram: 'https://www.instagram.com/ljhugsx/?hl=en',
        description: `A seasoned veteran with multiple UK street food awards. 
        
        Cajun Street food business operating in Bath and Bristol, specialising in grilled chicken and beef.`,
        image: ['sw/3/1.jpg', 'sw/3/2.jpg', 'sw/3/3.jpg', 'sw/3/4.jpg', 'sw/3/5.jpg', 'sw/3/6.jpg', 'sw/3/7.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/lj-hugs/3gvXCQ3IXnmyirbt9Ad44g?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/lj-hugs/3gvXCQ3IXnmyirbt9Ad44g?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D',
    },
    {
        id: 114,
        name: 'Gorilla Thai Kitchen', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'sw',
        instagram: 'https://www.instagram.com/gorillathaikitchen/?hl=en',
        description: `Gorilla Thai Kitchen is a beloved restaurant in Bristol, specialising in authentic Thai cuisine. 
        
        They offers a delightful menu featuring traditional dishes like Pad Thai, Massaman Curry, and Larb Chicken. 
        
        Whether you're craving a spicy curry or a refreshing Thai salad, Gorilla Thai Kitchen promises to deliver the true flavours of Thailand right to your table.`,
        image: ['sw/4/1.jpg', 'sw/4/2.jpg', 'sw/4/3.jpg', 'sw/4/4.jpg', 'sw/4/5.jpg', 'sw/4/6.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/gorilla-thai-kitchen-fishponds/OJqXES7NSIa134wUpNL83g?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522OJqXES7NSIa134wUpNL83g%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/gorilla-thai-kitchen-fishponds/OJqXES7NSIa134wUpNL83g?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522OJqXES7NSIa134wUpNL83g%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D&ps=1',
    },
    {
        id: 115,
        name: 'The Viet Kitchen', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'sw',
        instagram: 'https://www.instagram.com/thevietkitchen3/',
        description: `The Viet Kitchen, your go-to destination for authentic Vietnamese cuisine, offering the rich flavours of Vietnam with signature pho, banh mi, and more, all prepared with fresh, locally sourced ingredients`,
        image: ['sw/5/1.jpg', 'sw/5/2.jpg', 'sw/5/3.jpg', 'sw/5/4.jpg', 'sw/5/5.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/the-viet-kitchen/bv1M54-AQf2DNMtM1ppETQ?pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/the-viet-kitchen/bv1M54-AQf2DNMtM1ppETQ?pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D',
    },
    {
        id: 116,
        name: 'Ebisu', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'sw',
        instagram: 'https://www.instagram.com/ebisuwsm',
        description: `Ebisu Restaurant is a renowned dining spot in Weston-super-Mare, celebrated for its authentic Japanese cuisine. 
        
        Known for its fresh ingredients and meticulous preparation, the restaurant provides a genuine taste of Japan in a cosy and inviting atmosphere that transports you straight to the heart of Japan. `,
        image: ['sw/6/1.jpg', 'sw/6/2.jpg', 'sw/6/3.jpg', 'sw/6/4.jpg', 'sw/6/5.jpg', 'sw/6/6.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/ebisu-restaurant-wsm/x-fs0pE8TMCnz3VEh38o1w?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/ebisu-restaurant-wsm/x-fs0pE8TMCnz3VEh38o1w?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D',
    },
    {
        id: 117,
        name: 'Guoguo Yan', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'sw',
        instagram: '',
        description: `成都小吃 Guoguo Yan specialises in bold and complex flavors of authentic Sichuan cuisine, dedicated to bringing the essence of Chengdu\'s rich culinary tradition, offering a menu that showcases the unique blend of spicy, bold, and aromatic dishes characteristic of Sichuan cooking.`,
        image: ['sw/7/1.jpg', 'sw/7/2.jpg', 'sw/7/3.jpg', 'sw/7/4.jpg', 'sw/7/5.jpg', 'sw/7/6.jpg', 'sw/7/7.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/%E6%88%90%E9%83%BD%E5%B0%8F%E5%90%83-guoguo-yan/azuvYZ8aU8-m5PUbNlmoug?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/%E6%88%90%E9%83%BD%E5%B0%8F%E5%90%83-guoguo-yan/azuvYZ8aU8-m5PUbNlmoug?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D',
    },
    {
        id: 118,
        name: 'Milk Bun', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'sw',
        instagram: 'https://www.instagram.com/milkbunuk/?hl=en',
        description: `Founded in 2021, MILKBUN was born from two brothers with a passion for good food & good times. 
        
        Both brothers share the same ethos, utilising only the freshest of locally sourced ingredients; all whilst providing the perfect place to enjoy them.`,
        image: ['sw/8/1.jpg', 'sw/8/2.jpg', 'sw/8/3.jpg', 'sw/8/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/milk-bun/vLkpCdO4SrKSTVQd4XLIRw?ad_id=705436950926&campaign_id=19867211659&diningMode=DELIVERY&gclid=CjwKCAjw5qC2BhB8EiwAvqa41jNrqyzp3qbQ-JExIMLOX6mtBBwnuMFHW_Sx_dcY9BxtuqOwVtLXsxoC5_wQAvD_BwE&gclsrc=aw.ds&kw=&kwid=dsa-2185644236092&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D&placement=&utm_campaign=CM2256489-search-google-nonbrand_184_-99_GB-National_e_all_acq_cpc_en_SMB_DSA_Exact__dsa-2185644236092_705436950926_146510978079__c&utm_source=AdWords_NonBrand',
        orderlink: 'https://www.ubereats.com/gb/store/milk-bun/vLkpCdO4SrKSTVQd4XLIRw?ad_id=705436950926&campaign_id=19867211659&diningMode=DELIVERY&gclid=CjwKCAjw5qC2BhB8EiwAvqa41jNrqyzp3qbQ-JExIMLOX6mtBBwnuMFHW_Sx_dcY9BxtuqOwVtLXsxoC5_wQAvD_BwE&gclsrc=aw.ds&kw=&kwid=dsa-2185644236092&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D&placement=&utm_campaign=CM2256489-search-google-nonbrand_184_-99_GB-National_e_all_acq_cpc_en_SMB_DSA_Exact__dsa-2185644236092_705436950926_146510978079__c&utm_source=AdWords_NonBrand',
    },
    {
        id: 119,
        name: 'Pizzarova', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'sw',
        instagram: 'https://www.instagram.com/pizzarova/?hl=en',
        description: `Originating from their famous Land Rover, Pizzarova has gone from strength to strength. 
        
        Their sourdough is slowly fermented and made with a starter that's been fed for over 70 years. 
        
        This gives it a distinctly delicious taste and perfect crust. 
        
        Made fresh everyday from water, flour and salt. `,
        image: ['sw/9/1.jpg', 'sw/9/2.jpg', 'sw/9/3.jpg', 'sw/9/4.jpg', 'sw/9/5.jpg', 'sw/9/6.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/pizzarova-park-street/Pqodz4Z7W_WUu-Xn_9LjUQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/pizzarova-park-street/Pqodz4Z7W_WUu-Xn_9LjUQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D',
    },
    {
        id: 120,
        name: 'The Steamhouse - Cheltenham', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'sw',
        instagram: 'https://www.instagram.com/thesteamhouse/?hl=en',
        description: `Established in 2016, with our first store in Leamington Spa, we started off as a team of 3 selling 30 bagels a day that were bought in from a local home baker each morning, being filled to order & sent out to the small amount of risk taking customers trying out a new food business on the high street. 
        
        As word spread & our menu & products evolved, more & more people were coming through our doors each week. 
        
        Our supplier couldn't keep up with the demand & we began baking our own bagels in the middle of that summer. 
        
        As time has passed, our team has grown, with new locations opening and thousands of bagels being made week on week, alongside our ever growing range of cakes and doughnuts.`,
        image: ['sw/10/1.jpg', 'sw/10/2.jpg', 'sw/10/3.jpg', 'sw/10/4.jpg', 'sw/10/5.jpg', 'sw/10/6.jpg', 'sw/10/7.jpg', 'sw/10/8.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/the-steamhouse-cheltenham/Em4V4cUrW_C-Mhbh1bvzOw?ad_id=651996029727&campaign_id=19867211659&diningMode=DELIVERY&gclid=CjwKCAjw5qC2BhB8EiwAvqa41sJwOKjEw4zEBoKa_xWLmdvVJAe6GV_E4N_bCJueH3T4hhlwzqStohoCMAwQAvD_BwE&gclsrc=aw.ds&kw=&kwid=dsa-2185644236092&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D&placement=&utm_campaign=CM2256489-search-google-nonbrand_184_-99_GB-National_e_all_acq_cpc_en_SMB_DSA_Exact__dsa-2185644236092_651996029727_146510978079__c&utm_source=AdWords_NonBrand',
        orderlink: 'https://www.ubereats.com/gb/store/the-steamhouse-cheltenham/Em4V4cUrW_C-Mhbh1bvzOw?ad_id=651996029727&campaign_id=19867211659&diningMode=DELIVERY&gclid=CjwKCAjw5qC2BhB8EiwAvqa41sJwOKjEw4zEBoKa_xWLmdvVJAe6GV_E4N_bCJueH3T4hhlwzqStohoCMAwQAvD_BwE&gclsrc=aw.ds&kw=&kwid=dsa-2185644236092&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D&placement=&utm_campaign=CM2256489-search-google-nonbrand_184_-99_GB-National_e_all_acq_cpc_en_SMB_DSA_Exact__dsa-2185644236092_651996029727_146510978079__c&utm_source=AdWords_NonBrand',
    },
    {
        id: 121,
        name: 'The Dough Thrower', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'w',
        instagram: 'https://www.instagram.com/thedoughthrower/?hl=en',
        description: `The Dough Thrower's passion for pizza is unmatched, with a dough recipe perfected over 20 years and praised by customers as close to perfection. 
        
        Combined with top-quality, locally sourced ingredients, what's not to love!`,
        image: ['w/1/1.jpg', 'w/1/2.jpg', 'w/1/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/the-dough-thrower/4PziPMPZWjiFXHFsj6LSNQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/the-dough-thrower/4PziPMPZWjiFXHFsj6LSNQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D',
    },
    {
        id: 122,
        name: 'ANSH', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'w',
        instagram: 'https://www.instagram.com/anshcymru/?hl=en-gb',
        description: `Ansh is back after proudly winning the 2023 Uber Eats award for Sustainable Restaurant of the Year, recognised for their dedication to ethical farming practices and their passion for burgers. 
        
        The restaurant is led by two owners who share a vision to celebrate all the aspects that make Wales a truly extraordinary nation.`,
        image: ['w/2/1.jpg', 'w/2/2.jpg', 'w/2/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/ansh/UavR62baUqyOQCpMC9t9sg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D',
        orderlink: 'https://www.ubereats.com/gb/store/ansh/UavR62baUqyOQCpMC9t9sg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkJyaXN0b2wlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKWWRpemdXYURjVWdSSDllYVN5Nnk1STQlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDU0NTEzJTJDJTIybG9uZ2l0dWRlJTIyJTNBLTIuNTg3OTElN0Q%3D',
    },
    {
        id: 123,
        name: 'Poutine Box', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'w',
        instagram: 'https://www.instagram.com/poutinebox/',
        description: `A true taste of Canada in Cardiff. 
        
        Poutine Box quickly established themselves as a local favourite in Cardiff with their delicious and creative takes on the classic poutine.`,
        image: ['w/3/1.jpg', 'w/3/2.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/poutine-box/J0c5DXC1TEyTlg62dbFhyQ?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522J0c5DXC1TEyTlg62dbFhyQ%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/poutine-box/J0c5DXC1TEyTlg62dbFhyQ?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522J0c5DXC1TEyTlg62dbFhyQ%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D&ps=1',
    },
    {
        id: 124,
        name: 'The Pizza Boyz', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'w',
        instagram: 'https://www.instagram.com/the_pizzaboyz/?hl=en',
        description: `Established by twin brothers with a true passion for Italian cuisine, The Pizza Boyz have been serving up some of the nation's favourite pizzas from the heart of Swansea since 2018.`,
        image: ['w/4/1.jpg', 'w/4/2.jpg', 'w/4/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/the-pizza-boyz/t7BEqivlTkKKOsMPSAC0eg?pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/the-pizza-boyz/t7BEqivlTkKKOsMPSAC0eg?pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D',
    },
    {
        id: 125,
        name: 'Smokin\' Griddle', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'w',
        instagram: 'https://www.instagram.com/smokingriddle/?hl=en',
        description: `From humble beginnings as a cafe in Romford, two best friends decided to bring the Smokin\' Griddle brand to Wales. 
        
        Smokin\' Griddle have since established themselves as one of the very best in Cardiff and Swansea with their range of juicy beef burgers, crispy fried chicken, and thick shakes.`,
        image: ['w/5/1.jpg', 'w/5/2.jpg', 'w/5/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/smokin-griddle-swansea/7wspEUULQ0uWabgCc0AzBA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/smokin-griddle-swansea/7wspEUULQ0uWabgCc0AzBA?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D',
    },
    {
        id: 126,
        name: 'Bab Haus Mex', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'w',
        instagram: 'https://www.instagram.com/thebabhaus/?hl=en',
        description: `Bab Haus Mex presents a lively fusion of authentic Mexican recipes with strong influences from LA and the West Coast. 
        
        Dedicated to serving the best Mexican street food in South Wales, their focus is all about delivering pure, irresistible flavour!`,
        image: ['w/6/1.jpg', 'w/6/2.jpg', 'w/6/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/bab-haus-mex-caerphilly/KU_XD2UOWqG8VbYQKLN73w?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/bab-haus-mex-caerphilly/KU_XD2UOWqG8VbYQKLN73w?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D',
    },
    {
        id: 127,
        name: 'Wingstack', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'w',
        instagram: 'https://www.instagram.com/wingstackuk/?hl=en',
        description: `When you want wings in Cardiff, there's only one place to go--Wingstack. 
        
        Their passion for flavours shines through in every dish on the menu, from the flavour of the month to their smashed burgers. 
        
        Is there anything they can't do?`,
        image: ['w/7/1.jpg', 'w/7/2.jpg', 'w/7/3.jpg', 'w/7/4.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/wingstack-whitchurch-road/eG9yS0dnU52sMhvmZGCfag?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/wingstack-whitchurch-road/eG9yS0dnU52sMhvmZGCfag?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D',
    },
    {
        id: 128,
        name: 'Haystack Cafe', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'w',
        instagram: 'https://www.instagram.com/haystackcafe/?hl=en',
        description: `Haystack Cafe use quality ingredients to create innovative dishes that provide the people of Swansea and Merthyr Tydfil with an unforgettable brunch experience. 
        
        Will this Uber Eats Restaurant of the Year Award 2023 finalist take the crown in 2024?`,
        image: ['w/8/1.jpg', 'w/8/2.jpg', 'w/8/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/haystack-cafe/QlVc7x00WHqj6R2cvi5_Qg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/gb/store/haystack-cafe/QlVc7x00WHqj6R2cvi5_Qg?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D',
    },
    {
        id: 129,
        name: 'Fowl & Fury', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'w',
        instagram: 'https://www.instagram.com/fowlandfury/?hl=en',
        description: `Flying the flag for Nashville hot chicken in Wales, Fowl & Fury\'s fresh hot chicken sandwiches have enabled them to establish a cult following in the heart of Cardiff.`,
        image: ['w/9/1.jpg', 'w/9/2.jpg', 'w/9/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/gb/store/fowl-%26-fury/Wuw4Q54cUeuFdPVD2kS1VQ?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522Wuw4Q54cUeuFdPVD2kS1VQ%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D&ps=1',
        orderlink: 'https://www.ubereats.com/gb/store/fowl-%26-fury/Wuw4Q54cUeuFdPVD2kS1VQ?mod=storeDeliveryTime&modctx=%257B%2522entryPoint%2522%253A%2522store-auto-surface%2522%252C%2522encodedStoreUuid%2522%253A%2522Wuw4Q54cUeuFdPVD2kS1VQ%2522%257D&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D&ps=1',
    },
    {
        id: 130,
        name: 'Tuk Tuk', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'w',
        instagram: 'https://www.instagram.com/tuktuk_cardiff/?hl=en-gb',
        description: `Ride through the flavours of Thailand with Tuk Tuk, an independent Thai restaurant aiming to satisfy the sweet, sour, salty and spicy flavours of Cardiff`,
        image: ['w/10/1.jpg', 'w/10/2.jpg', 'w/10/3.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/store/tuk-tuk/PPn9T9myU5m0Ay_8wPcQ9Q?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D&ps=1&utm_source=menu-maker',
        orderlink: 'https://www.ubereats.com/store/tuk-tuk/PPn9T9myU5m0Ay_8wPcQ9Q?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D&ps=1&utm_source=menu-maker',
    },
    {
        id: 131,
        name: 'Andhra Bhavan', 
        // owner: 'Owner 1 & Owner 2',                 // Used for winners, but will default to restaurant name
        region: 'i',
        instagram: 'https://www.instagram.com/andhrabhavan_ireland/?hl=en',
        description: `As pioneers of authentic South Indian breakfast, we're proud to offer a delightful selection including crispy Dosas, soft Idlis, flavorful Vadas, delectable Uttappams, and the unique Peserattu. 
        
        But that's not all. Our culinary adventure takes you further with the crown jewel of Hyderabadi cuisine - our meticulously prepared Biryani. 
        
        From the aromatic Veg Biryani to the succulent Chicken and Lamb variations, each biryani tells a story of tradition and excellence. 
        
        Delight in the tantalizing aroma and taste of live Pani Puri, the satisfying crunch of Vada Pav, and the flavorful medley of Samosa Chaat. 
        
        It's an experience that elevates dining to an art. We know that diversity matters, so our offerings include something for everyone. 
        
        Our thoughtfully crafted Taalis cater to various preferences - from the wholesome and satisfying veg and vegan options to the indulgence of non-veg delights.`,
        image: ['i/11/1.jpg', ],
        // winImage: '2024-winner-img.jpg',            // Add this for the winner card
        // location: 'London',                         // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/ie/store/andhra-bhavan-marlborough-st/3dVFbSFBUIqlh8cg-DyrRQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D',
        orderlink: 'https://www.ubereats.com/ie/store/andhra-bhavan-marlborough-st/3dVFbSFBUIqlh8cg-DyrRQ?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkNhcmRpZmYlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjJDaElKOVZQc05OUUNia2dSRG1lR1pkc0dOQlElMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIyZ29vZ2xlX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNTEuNDgzNzA3JTJDJTIybG9uZ2l0dWRlJTIyJTNBLTMuMTY4MDk2MiU3RA%3D%3D',
    },
];


/* export const RESTAURANT_SHORTLIST = [
    {
        id: 1,
        name: 'Restaurant 1',
        // owner: 'Owner 1 & Owner 2',                          // Used for winners, but will default to restaurant name
        region: 'ne',
        instagram: 'restaurant1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant1.jpg', 'placeholder-restaurants/restaurant8.jpg', 'placeholder-restaurants/restaurant5.jpg'],
        // winImage: '2023-winners/roty-winner-2023.jpg',       // Add this for the winner card!
        // location: 'London',                                  // Optional, used for winners, but will default to region label
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 2,
        name: 'Restaurant 2',
        region: 'l',
        instagram: 'restaurant2',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant2.jpg', 'placeholder-restaurants/restaurant1.jpg', 'placeholder-restaurants/restaurant3.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 3,
        name: 'Restaurant 3',
        region: 'se',
        instagram: 'restaurant3',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant3.jpg', 'placeholder-restaurants/restaurant2.jpg', 'placeholder-restaurants/restaurant4.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 4,
        name: 'Restaurant 4',
        region: 'mid',
        instagram: 'restaurant4',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant4.jpg', 'placeholder-restaurants/restaurant9.jpg', 'placeholder-restaurants/restaurant5.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 5,
        name: 'Restaurant 5',
        region: 'wm',
        instagram: 'restaurant5',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant5.jpg', 'placeholder-restaurants/restaurant10.jpg', 'placeholder-restaurants/restaurant6.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 6,
        name: 'Restaurant 6',
        region: 'y',
        instagram: 'restaurant6',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant6.jpg', 'placeholder-restaurants/restaurant11.jpg', 'placeholder-restaurants/restaurant3.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 7,
        name: 'Restaurant 7',
        region: 'sw',
        instagram: 'restaurant7',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant7.jpg', 'placeholder-restaurants/restaurant12.jpg', 'placeholder-restaurants/restaurant16.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 8,
        name: 'Restaurant 8',
        region: 'w',
        instagram: 'restaurant8',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant8.jpg', 'placeholder-restaurants/restaurant13.jpg', 'placeholder-restaurants/restaurant7.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 9,
        name: 'Restaurant 9',
        region: 's',
        instagram: 'restaurant9',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant9.jpg', 'placeholder-restaurants/restaurant14.jpg', 'placeholder-restaurants/restaurant8.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 10,
        name: 'Restaurant 10',
        region: 'i',
        instagram: 'restaurant10',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant10.jpg', 'placeholder-restaurants/restaurant15.jpg', 'placeholder-restaurants/restaurant9.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 11,
        name: 'Restaurant 11',
        region: 'ni',
        instagram: 'restaurant11',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant11.jpg', 'placeholder-restaurants/restaurant1.jpg', 'placeholder-restaurants/restaurant10.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 12,
        name: 'Restaurant 12',
        region: 'nw',
        instagram: 'restaurant12',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant12.jpg', 'placeholder-restaurants/restaurant2.jpg', 'placeholder-restaurants/restaurant11.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 13,
        name: 'Restaurant 13',
        region: 'ne',
        instagram: 'restaurant13',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant13.jpg', 'placeholder-restaurants/restaurant4.jpg', 'placeholder-restaurants/restaurant6.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 14,
        name: 'Restaurant 14',
        region: 'l',
        instagram: 'restaurant14',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant14.jpg', 'placeholder-restaurants/restaurant7.jpg', 'placeholder-restaurants/restaurant12.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 15,
        name: 'Restaurant 15',
        region: 'se',
        instagram: 'restaurant15',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant15.jpg', 'placeholder-restaurants/restaurant6.jpg', 'placeholder-restaurants/restaurant13.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 16,
        name: 'Restaurant 16',
        region: 'mid',
        instagram: 'restaurant16',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant16.jpg', 'placeholder-restaurants/restaurant8.jpg', 'placeholder-restaurants/restaurant14.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 17,
        name: 'Restaurant 17',
        region: 'wm',
        instagram: 'restaurant17',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant1.jpg', 'placeholder-restaurants/restaurant9.jpg', 'placeholder-restaurants/restaurant13.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 18,
        name: 'Restaurant 18',
        region: 'y',
        instagram: 'restaurant18',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant2.jpg', 'placeholder-restaurants/restaurant10.jpg', 'placeholder-restaurants/restaurant12.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 19,
        name: 'Restaurant 19',
        region: 'sw',
        instagram: 'restaurant19',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant3.jpg', 'placeholder-restaurants/restaurant11.jpg', 'placeholder-restaurants/restaurant2.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 20,
        name: 'Restaurant 20',
        region: 'w',
        instagram: 'restaurant20',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant4.jpg', 'placeholder-restaurants/restaurant12.jpg', 'placeholder-restaurants/restaurant1.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 21,
        name: 'Restaurant 21',
        region: 's',
        instagram: 'restaurant21',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant5.jpg', 'placeholder-restaurants/restaurant13.jpg', 'placeholder-restaurants/restaurant3.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 22,
        name: 'Restaurant 22',
        region: 'i',
        instagram: 'restaurant22',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant6.jpg', 'placeholder-restaurants/restaurant14.jpg', 'placeholder-restaurants/restaurant4.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 23,
        name: 'Restaurant 23',
        region: 'ni',
        instagram: 'restaurant23',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant7.jpg', 'placeholder-restaurants/restaurant15.jpg', 'placeholder-restaurants/restaurant5.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 24,
        name: 'Restaurant 24',
        region: 'nw',
        instagram: 'restaurant24',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant8.jpg', 'placeholder-restaurants/restaurant16.jpg', 'placeholder-restaurants/restaurant6.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 25,
        name: 'Restaurant 25',
        region: 'ne',
        instagram: 'restaurant25',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant9.jpg', 'placeholder-restaurants/restaurant1.jpg', 'placeholder-restaurants/restaurant7.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 26,
        name: 'Restaurant 26',
        region: 'l',
        instagram: 'restaurant26',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant10.jpg', 'placeholder-restaurants/restaurant2.jpg', 'placeholder-restaurants/restaurant8.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 27,
        name: 'Restaurant 27',
        region: 'se',
        instagram: 'restaurant27',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant11.jpg', 'placeholder-restaurants/restaurant3.jpg', 'placeholder-restaurants/restaurant9.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 28,
        name: 'Restaurant 28',
        region: 'mid',
        instagram: 'restaurant28',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant12.jpg', 'placeholder-restaurants/restaurant4.jpg', 'placeholder-restaurants/restaurant10.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 29,
        name: 'Restaurant 29',
        region: 'wm',
        instagram: 'restaurant29',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant13.jpg', 'placeholder-restaurants/restaurant5.jpg', 'placeholder-restaurants/restaurant11.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 30,
        name: 'Restaurant 30',
        region: 'y',
        instagram: 'restaurant30',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant14.jpg', 'placeholder-restaurants/restaurant6.jpg', 'placeholder-restaurants/restaurant12.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
    {
        id: 31,
        name: 'Restaurant 31',
        region: 'l',
        instagram: 'restaurant31',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquam, sapien nec tincidunt fermentum, mi purus tincidunt sem, nec tincidunt ex nunc ut nisl.',
        image: ['placeholder-restaurants/restaurant8.jpg', 'placeholder-restaurants/restaurant16.jpg', 'placeholder-restaurants/restaurant6.jpg'],
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/',
    },
]; */

export function findRestaurantData(search) {
    search = search.toLowerCase();
    // replace - with '' in the search string
    search = search.replace(/-/g, '');
    for (const restaurant of RESTAURANT_SHORTLIST) {
        const n = restaurant.name.toLowerCase().replace(/ /g, '');
        if (restaurant.name.toLowerCase() === search || n === search || restaurant.instagram === search || restaurant.id === parseInt(search)) {
            return restaurant;
        }
        if (restaurant.match) {
            if (restaurant.match === search) {
                return restaurant;
            }
            if (Array.isArray(restaurant.match)) {
                if (restaurant.match.includes(search)) {
                    return restaurant;
                }
            }
        }
    }
    // Not found
    return null;
}

/* import logo from './logo.svg'; */
import './App.css';
import { Main } from './uber/containers/main';
/* import NominatePage from './uber/nominate-page';
import VotePage from './uber/vote-page';
import FinalistsPage from './uber/finalists-page';
import ShowFinalistsPage from './uber/show-finalists-page';
import WinnerPage from './uber/winner-page'; */

function App() {
  return (
    <>
      <Main></Main>
      {/* <NominatePage></NominatePage> */}
      {/* <VotePage></VotePage> */}
      {/* <FinalistsPage></FinalistsPage> */}
      {/* <ShowFinalistsPage></ShowFinalistsPage> */}
      {/*<WinnerPage></WinnerPage>*/}
    </>
  );
}

export default App;

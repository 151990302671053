export const PREV_WINNERS = {
    roty2023: {
        image: '2023-winners/roty-winner-2023.jpg',
        name: 'Sandwich Sandwich',
        location: 'South West',
        instagram: 'https://www.instagram.com/sandwichsandwichuk/',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/gb/store/sandwich-sandwich/ZJDtRp6YUSm4WkBCPMoUvw',
        // orderlink: 'https://www.ubereats.com/gb/store/sandwich-sandwich/nTRTKalkS6m1rvcWocu2tw',
    },
    femaleChef2023: {
        image: '2023-winners/female-chef-2023.jpg',
        name: 'Natalie Francis',
        restaurant: "Doreen's Kitchen",
        location: 'Midlands and East Anglia',
        instagram: 'https://www.instagram.com/_doreenskitchen_/',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/gb/store/doreens-kitchen/ChrLevRMWZGgsYHKEtZaVA',
    },
    sustainable2023: {
        image: '2023-winners/sustainable-2023.jpg',
        name: 'Aled Hill and Shaun Jones',
        restaurant: 'ANSH CYMRU',
        location: 'Wales',
        instagram: 'https://www.instagram.com/anshcymru/',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/gb/store/ansh/UavR62baUqyOQCpMC9t9sg',
    },
    health2023: {
        image: '2023-winners/health-2023.jpg',
        name: 'Henri Salameh and Emile Salame',
        restaurant: 'Eat Lebö',
        location: 'London',
        instagram: 'https://www.instagram.com/eatlebo/',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/gb/store/eat-lebo-ruislip',
    },
    community2023: {
        image: '2023-winners/community-2023.jpg',
        name: 'Rashpal Singh',
        restaurant: 'Taste From India',
        location: 'Midlands and East Anglia',
        instagram: 'https://www.instagram.com/tasteofindia/',
        sharelink: 'https://www.ubereats.com/',
        orderlink: 'https://www.ubereats.com/gb/store/taste-of-india/Ud7NNE8vS72nip4O4j177A',
    }
}
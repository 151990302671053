import './trailblazer-card.css';

import LocationIcon from '../../../uber-assets/location-icon.svg';
import { INFO_ICON, INSTA_ICON } from '../../../uber-assets/inline-svgs';
import FinalistRibbon from '../../../uber-assets/finalist-ribbon.svg';
import WinnerRibbon from '../../../uber-assets/winner-ribbon.svg';
import Carousel from 'react-multi-carousel';
import { CustomDot } from '../carousel/custom-dot';
import React, { useEffect, useRef } from 'react';

export function TrailblazerCard({ trailblazer, infoFunction, instagramFunction, orderFunction, finalist, showWinner }) {
    const carouselRef = useRef(null);

    // This useEffect is used to reset the carousel to the first slide when the card is rendered,
    // but it is currently not working as expected (does weird stuff).
    /*  useEffect(() => {
        if (carouselRef.current) {
            setTimeout(() => {
                carouselRef.current.goToSlide(0);
            }, 100);
        }
    }, [trailblazer]); */

    return (
        <div className='trailblazer-card card-anim-start'>
            {!Array.isArray(trailblazer.image) &&
                <div className="trailblazer-card__image" style={{backgroundImage: `url(./img/${trailblazer.image})`}}></div>
            }
            {Array.isArray(trailblazer.image) &&
                <Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={true}
                    customDot={<CustomDot />}
                    arrows={true}
                    responsive={{all: {breakpoint:{min: 0, max: 1000000}, items: 1}}}
                    ssr={false} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={false}
                    autoPlaySpeed={3000}
                    keyBoardControl={false}
                    transitionDuration={500}
                    containerClass="trailblazer-card__image"
                    dotListClass=''
                    ref={carouselRef}
                >
                    {
                        trailblazer.image.map((image, index) => {
                            return (
                                <div key={index} className="trailblazer-card__image carousel-inner" style={{backgroundImage: `url(./img/${image})`}}></div>
                            )
                        })
                    }
                </Carousel>
            }
            <div className='trailblazer-card__info'>
                <h2 className='trailblazer-card__info__name'>{trailblazer.name}</h2>
                {trailblazer.restaurant !== trailblazer.name && trailblazer.restaurant !== '' && <h3>{trailblazer.restaurant}</h3>}
                <div className='trailblazer-card__info__location'>
                    <img className='small-info-icon' src={LocationIcon} alt='Location icon' />
                    <p>{trailblazer.location}</p>
                </div>
                <div className='grow'></div>
                <div className='trailblazer-card__info__buttons'>
                    <button className='trailblazer-card__info__buttons__info square light' onClick={() => infoFunction(trailblazer)}>{INFO_ICON}</button>
                    <button className='trailblazer-card__info__buttons__instagram square light' onClick={() => instagramFunction(trailblazer)}>{INSTA_ICON}</button>
                    <button className='trailblazer-card__info__buttons__order black' onClick={() => orderFunction(trailblazer)}>Order</button>
                </div>
            </div>
            {finalist &&
                <img className='card-ribbon' src={(showWinner && trailblazer.isWinner) ? WinnerRibbon : FinalistRibbon} alt='Finalist' />
            }
        </div>
    )
}

import { PHASES } from '../model/phases';
import Lottie from 'react-lottie';
import LogoAnim from '../../uber-assets/eu-roty-logo-anim.json';

export function AboutHeader({scrollToSection, phase}) {
    return (
        <header className="section-container header-container" id="about">
            <div className="header-img-area">
                {/* <img className='header-img' src={HeaderImg} alt="Uber Eats Restaurant of the Year Logo" /> */}
                
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: LogoAnim,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice'
                            }
                        }}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '100%',
                            maxWidth: '100%',
                            height: 'auto',
                            zIndex: 1,
                        }}
                    />
                
            </div>
            <div className="header-split">
                <div className="content">
                    {(phase === PHASES.VOTE) &&
                        <>
                            <h1>Who will be 2024's Restaurant of the Year?</h1>
                            <p>We're back again this year with the 130 best restaurants on Uber Eats across the UK and Ireland, where the winning restaurant will receive a cash prize of £100,000. All runners-up will receive a cash prize to help grow their business and a marketing support package.</p>
                            <p>The final judging day takes place later this year, with a best-in-class judging line up.</p>
                            <p>Vote for your favourite restaurant below for them to be in with a chance of winning!</p>
                            <button className='black' onClick={() => scrollToSection('roty')}>Vote Now</button>
                        </>
                    }
                    {(phase === PHASES.VOTE_ENDED) &&
                        <>
                            <h1>Voting is now closed.</h1>
                            <h2>We will announce the 12 Restaurant of the Year finalists tomorrow 17th of October. </h2>
                            <p>Today we are pleased to announce our incredible 12 Trailblazers finalists who make a positive difference in their communities and the world, check them out below!</p>
                            {/* <p>The final judging day takes place later this year, with a best-in-class judging line up.</p> */}
                            {/* <p>Voting is now closed. We will announce the 12 national finalists along with our Trailblazer finalists on the 16th of October.</p> */}
                            <div className='extra-bottom-pad'></div>
                            <div className='extra-bottom-pad'></div>
                            <div className='extra-bottom-pad'></div>
                        </>
                    }
                    {phase === PHASES.FINALISTS &&
                        <>
                            <h1>Who will be 2024's Restaurant of the Year?</h1>
                            <p>We're thrilled to announce the 12 national finalists, voted by you, who will now compete for the grand prize of £100,000. All runners-up will also receive a cash prize to help expand their business and a marketing support package.</p>
                            <p className='extra-bottom-pad'>Find below our 12 finalists, voted by you, all eligible to become the 2024 Uber Eats Restaurant of the Year.</p>
                            <div className='extra-bottom-pad'></div>
                            <div className='extra-bottom-pad'></div>
                        </>
                    }
                    {phase === PHASES.WINNERS &&
                        <>
                            <h1>Discover our 2024 winners!</h1>
                            <p className='extra-bottom-pad'>Uber Eats scoured the UK and Ireland for the best local restaurants and the public voted in their thousands for their favourites. Following a day with our expert judges, we are thrilled to announce the winners of the Uber Eats Restaurant of the Year Awards 2024. Discover this year’s Restaurant of the Year and industry Trailblazers below.</p>
                            <div className='extra-bottom-pad'></div>
                        </>
                    }
                </div>
            </div>
        </header>
    )
}
